import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import NavbarTwo from '../components/NavbarTwo';
import { useParams } from 'react-router-dom'


const ProjectSettings = () => {
    const { user } = useAuthContext();
    const [project, setProject] = useState({})
    const [loading, setLoading] = useState(false)
    const [updatedTitle, setUpdatedTitle] = useState()
    const [updatedDescription, setUpdatedDescription] = useState()
    const [updatedModel, setUpdatedModel] = useState()
    const [updatedExamples, setUpdatedExamples] = useState()
    const [updatedEnd, setUpdatedEnd] = useState()
    const [updatedUITemplate, setUpdatedUITemplate ] = useState()
    const [primaryColor, setPrimaryColor] = useState()
    const [secondaryColor, setSecondaryColor] = useState()
    const [bgColor, setBgColor] = useState()
    const [mainTextColor, setMainTextColor] = useState()
  
    const params = useParams()
    const id = params.id

    useEffect(() => {
        setLoading(true)
        const fetchProject = async () => {
          const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/external/' + id, {
            method: 'GET',
          })
    
          const json = await response.json()
          
          if (response.ok) {
            setProject(json)
          }
          if (!response.ok) {
            console.log('error')
          }
          setLoading(false)
        }
        fetchProject()
      }, [id])

    const updateGeneral = async (e) => {
        e.preventDefault()
        const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/projects/' + id, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({
                title: updatedTitle,
                description: updatedDescription,
            })
        })
        const json = await response.json()
        if (response.ok) {
            setProject(json)
            window.location.reload()

        }
        if (!response.ok) {
            console.log('error')
        }
    }

    const updateAI = async (e) => {
        e.preventDefault()
        const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/projects/' + id, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({
                modelUsed: updatedModel,
                prompt: updatedExamples,
                end: updatedEnd
            })
        })
        const json = await response.json()
        if (response.ok) {
            setProject(json)
            console.log(json)
            window.location.reload()

        }
        if (!response.ok) {
            console.log('error')
        }
    }

    const updateUI = async (e) => {
        e.preventDefault()
        const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/projects/' + id, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ uiTemplate: updatedUITemplate, primaryColor, secondaryColor, bgColor, mainTextColor})
        })
        const json = await response.json()
        if (response.ok) {
            setProject(json)
            console.log(json)
            window.location.reload()

        }
        if (!response.ok) {
            console.log('error')
        }
    }




    if (!user) {
        return (
          <div className="pt-20 w-full min-h-screen flex-2 text-center">
            <p className="mt-2 text-md font-medium text-gray-900">Not logged in</p>
            <h3 className="mt-2 text-sm font-medium text-gray-900">Please <a className='underline text-indigo-500' href='/login'>sign in to your account</a> or <a className='underline text-indigo-500' href='signup'>create a new account</a></h3>
          </div>
        )
      }

    if (loading) {
      return (
        <div className="pt-20 w-full min-h-screen flex-2 text-center">
          <p className="mt-2 text-md font-medium text-gray-900">Loading...</p>
        </div>
      )
    }
    
    return (
        <div className='w-full min-h-screen flex-2'>
        <NavbarTwo />
        <header className="bg-white shadow">
          <div className="mx-20 max-w-7xl py-6 px-4 sm:px-6">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">Project Settings</h1>
            <p className='pt-2'>{project.title}</p>
          </div>
        </header>
      <>
      <main className='mx-20 py-6 sm:px-6'>
        <div>
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">General</h3>
                <p className="mt-1 text-sm text-gray-600">
                  This information will be displayed publicly so be careful what you share.
                </p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <form onSubmit={updateGeneral}>
                <div className="shadow sm:overflow-hidden sm:rounded-md">
                  <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-3 gap-6">
                      <div className="col-span-3 sm:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Project Name
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            type="text"
                            className="block w-full flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            defaultValue={project.title}
                            onChange={(e) => setUpdatedTitle(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
  
                    <div>
                      <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                        Project Description
                      </label>
                      <div className="mt-1">
                        <textarea
                          rows={3}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          defaultValue={project.description}
                          onChange={(e) => setUpdatedDescription(e.target.value)}
                        />
                      </div>
                      <p className="mt-2 text-sm text-gray-500">
                        Brief description for your project.
                      </p>
                    </div>

                  </div>
                  <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
  
        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>
  
        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">AI</h3>
                <p className="mt-1 text-sm text-gray-600">Configure your project's AI settings.</p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <form onSubmit={updateAI}>
                <div className="overflow-hidden shadow sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
  
                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="model" className="block text-sm font-medium text-gray-700">
                          Model
                        </label>
                        <select
                            onChange={(e) => setUpdatedModel(e.target.value)}
                            id="model"
                            name="model"
                            value={project.modelUsed}
                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        >
                            <option value={"text-davinci-003"}>text-davinci-003</option>
                            <option value={"text-curie-001"}>text-curie-001</option>
                            <option value={"text-babbage-003"}>text-babbage-003</option>
                            <option value={"text-ada-002"}>text-ada-002</option>
                        </select>
                      </div>  
                    </div>
                    <div className='pt-5'>
                      <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                        Examples
                      </label>
                      <div className="mt-1">
                        <textarea
                          rows={15}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          defaultValue={project.prompt}
                          onChange={(e) => setUpdatedExamples(e.target.value)}
                        />
                      </div>
                      <p className="mt-2 text-sm text-gray-500">
                        Write a few examples of how you would like the program to behave.
                      </p>
                    </div>


                    <div className='pt-5'>
                      <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                        End
                      </label>
                      <div className="mt-1">
                        <textarea
                            rows={1}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            defaultValue={project.end}
                            onChange={(e) => setUpdatedEnd(e.target.value)}
                        />
                      </div>
                      <p className="mt-2 text-sm text-gray-500">
                        This is the prompt that the model will use to respond to the command.
                      </p>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
  
        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>
  
        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Frontend</h3>
                <p className="mt-1 text-sm text-gray-600">What do you want your project to look like?</p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <form onSubmit={updateUI}>
                <div className="overflow-hidden shadow sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
  
                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="model" className="block text-sm font-medium text-gray-700">
                          Layout
                        </label>
                        <select
                            onChange={(e) => setUpdatedUITemplate(e.target.value)}
                            id="model"
                            name="model"
                            value={project.uiTemplate}
                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        >
                            {/* <option value={project.uiTemplate}>Select</option> */}
                            <option value={"LayoutOne"}>Layout #1</option>
                            <option value={"LayoutTwo"}>Layout #2</option>
                        </select>
                      </div>

                  </div>
                  <div className='flex flex-row pt-2'>
                            <div className='basis-1/5 py-3'>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Primary Color
                                </label>
                                <p className="block text-sm font-medium text-gray-700">{primaryColor ? primaryColor : project.primaryColor}</p>
                                <div className="mt-1">
                                <input
                                    type="color"
                                    className="block rounded-md"
                                    value={primaryColor ? primaryColor : project.primaryColor}
                                    onChange={(e) => setPrimaryColor(e.target.value)}
                                />
                                </div>
                            </div>
                            <div className='basis-1/5 py-3'>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Secondary Color
                                </label>
                                <p className="block text-sm font-medium text-gray-700">{secondaryColor ? secondaryColor : project.secondaryColor}</p>
                                <div className="mt-1">
                                    <input
                                    type="color"
                                    className="block rounded-md"
                                    value={secondaryColor ? secondaryColor : project.secondaryColor}
                                    onChange={(e) => setSecondaryColor(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='basis-1/5 py-3'>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Background Color
                                </label>
                                <p className="block text-sm font-medium text-gray-700">{bgColor ? bgColor : project.bgColor}</p>
                                <div className="mt-1">
                                <input
                                    type="color"
                                    className="block rounded-md"
                                    value={bgColor ? bgColor : project.bgColor}
                                    onChange={(e) => setBgColor(e.target.value)}

                                />
                                </div>
                            </div>
                            <div className='basis-1/5 py-3'>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Main Text Color
                                </label>
                                <p className="block text-sm font-medium text-gray-700">{mainTextColor ? mainTextColor : project.mainTextColor}</p>
                                <div className="mt-1">
                                <input
                                    type="color"
                                    className="block rounded-md"
                                    value={mainTextColor ? mainTextColor : project.mainTextColor}
                                    onChange={(e) => setMainTextColor(e.target.value)}
                                />
                                </div>
                            </div>
                      </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Admin</h3>
                <p className="mt-1 text-sm text-gray-600">Authentication and payments.</p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <form>
              <div className="shadow sm:overflow-hidden sm:rounded-md">
                  <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-3 gap-6">
                        <div className="col-span-3 sm:col-span-2">
                            <label className="block text-sm font-medium text-gray-700">
                            Coming soon
                            </label>
                        </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Deploy</h3>
                <p className="mt-1 text-sm text-gray-600">Domain and hosting.</p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
                <form>
                    <div className="shadow sm:overflow-hidden sm:rounded-md">
                        <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div className="grid grid-cols-3 gap-6">
                                <div className="col-span-3 sm:col-span-2">
                                    <label className="block text-sm font-medium text-gray-700">
                                    Coming soon
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
          </div>
        </div>
        </main>
      </>
      </div>
    )
  }
  

  export default ProjectSettings