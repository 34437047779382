import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';


const DeployedChatbot = () => {
  const [messages, setMessages] = useState([
    { id: 1, user: 'Chatbot', text: 'Hi there, how can I help you today?' },
  ]);
  const [prompt, setPrompt] = useState('Hi there, how can I help you today?');
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [chatProject, setChatProject] = useState({})

  const params = useParams()
  const id = params.id

  useEffect(() => {
    const fetchChatProject = async () => {
      const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/external/chatproject/' + id, {
          method: 'GET',
      })

      const json = await response.json()

      if (response.ok) {
          setChatProject(json)
      }
      if (!response.ok) {
          console.log('error')
      }
    }
    fetchChatProject()
  }, [id])

  async function chat(e) {
    e.preventDefault();
    setLoading(true)
    setMessages([
      ...messages,
      { id: messages.length + 1, user: 'You', text: inputValue },
    ])
    setInputValue('')

    let query = prompt + ' ' + inputValue;
    console.log(query)

    const id = chatProject.vector_store_id;

    const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/external/chat/' + id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ query })
    })

    const json = await response.json()

    if (response.ok) {
      query = query + ' ' + json;
      setLoading(false);
      setPrompt(query);
      setMessages([
        ...messages,
        { id: messages.length + 1, user: 'You', text: inputValue},
        { id: messages.length + 2, user: 'Chatbot', text:json },
      ])
    }

    if (!response.ok) {
      setLoading(false);
      setError('Error')
    }
    console.log(messages);
    console.log(json)
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <header>
        <title>{chatProject.title}</title>
        <link rel="icon" href="/favicon.ico" />
      </header>
      <h1 className="text-6xl max-w-[708px] font-bold text-slate-900">
        {chatProject.title}
      </h1>
      <div className="mt-12 sm:mt-20 bg-white rounded-lg shadow-lg max-w-xl w-full">
        <div className="p-4">
          {messages.map((message) => (
            <div
              className={`flex items-start ${message.user === 'You' ? 'justify-end' : ''}  ${messages.length === 1 ? 'mb-36' : 'mb-4'}`}
              key={message.id}
            >
              <div className={`border rounded-lg px-4 py-2 ${message.user === 'You' ? 'bg-gray-200 text-gray-900' : 'bg-gray-50 text-gray-800'}`}>
                <div className="flex justify-between">
                  <p className={`font-bold ${message.user === 'You' ? 'text-right' : ''}`}>
                    {message.user}
                  </p>
                </div>
                <p>{message.text}</p>
              </div>
            </div>
          ))}
        </div>
        <form onSubmit={chat}>
          <div className="flex items-center p-4 border-t border-gray-300">
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              className="flex-1 bg-gray-50 rounded-xl py-2 px-4 mr-4 text-gray-800 font-medium border border-gray-200 focus:outline-none focus:border-black"
              placeholder="Type your message here"
            />
            {!loading && (
            <button
              type="submit"
              className="bg-black hover:bg-gray-600 text-white font-medium py-2 px-4 rounded-xl"
            >
             Send
            </button>
            )}
            {loading && (
              <button
                className="bg-black hover:bg-gray-600 text-white font-medium py-2 px-4 rounded-xl"
                disabled
              >
                Loading...
              </button>
            )}
          </div>
          {error && (
              <p className="flex items-center px-4 py-2">{error}</p>
            )}
        </form>
      </div>
    </div>
  );
};

export default DeployedChatbot;