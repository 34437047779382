import React, { useState, useEffect } from 'react';
import { PlusIcon } from '@heroicons/react/20/solid'
import NavbarTwo from '../components/NavbarTwo';
import { useAuthContext } from '../hooks/useAuthContext';
import { ArrowLongRightIcon, CpuChipIcon } from '@heroicons/react/24/outline'


const Models = () => {
  const [zeroProjects, setZeroProjects] = useState(false);
  const [fineTunes, setFineTunes] = useState([])
  const [loading, setLoading] = useState(false)
  const { user } = useAuthContext();


  useEffect(() => {
    const fetchModels = async () => {
      setLoading(true)
      const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/finetune/getfinetunerecords', {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })
      const json = await response.json()

      if (response.ok) {
        setFineTunes(json)
        if (json.length === 0) {
          setZeroProjects(true)
        }
      }
      setLoading(false)
  }
  if (user) {
    fetchModels()
  }
  }, [user])


  if (!user) {
    return (
      <div className="pt-20 w-full min-h-screen flex-2 text-center">
        <p className="mt-2 text-md font-medium text-gray-900">Not logged in</p>
        <h3 className="mt-2 text-sm font-medium text-gray-900">Please <a className='underline text-indigo-500' href='/login'>sign in to your account</a> or <a className='underline text-indigo-500' href='signup'>create a new account</a></h3>
      </div>
    )
  }

  return (
      <div className='w-full min-h-screen flex-2'>
        <NavbarTwo />
        <header className="bg-white shadow">
          <div className="mx-20 max-w-7xl py-6 px-4 sm:px-6">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">Fine Tunes</h1>
          </div>
        </header>

        <main>
          {loading? (
            <p className="mx-20 py-6 sm:px-6 text-black">Loading...</p>
            ): zeroProjects ? (
              <div className="mx-20 py-6 px-4 sm:px-6">
                <div className="text-center">
                    <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                    >
                        <path
                        vectorEffect="non-scaling-stroke"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                        />
                    </svg>
                    <h3 className="mt-2 text-sm font-medium text-gray-900">No fine tuned models</h3>
                    <p className="mt-1 text-sm text-gray-500">Get started by fine tuning your own model.</p>
                    <div className="mt-6">
                        <a
                        href="/modelfinetuning"
                        className="inline-flex items-center rounded-md border border-transparent bg-purple-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-900 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                        >
                        <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                        New Fine Tune
                        </a>
                    </div>
                </div>
            </div>
          ) : (
            <div className="mx-20 py-6 px-4 sm:px-6">
              <div className="rounded-md bg-purple-50 p-4 mb-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <CpuChipIcon className="h-5 w-5 text-purple-700" aria-hidden="true" />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-purple-900">Create a new fine-tuned model</p>
                    <p className="mt-3 text-sm md:mt-0 md:ml-6">
                      <a href="/modelfinetuning" className="whitespace-nowrap font-medium text-purple-900 hover:text-purple-600">
                        Go
                        <span aria-hidden="true"> &rarr;</span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
            {fineTunes && fineTunes.map((fineTune) => (
              <li key={fineTune._id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
              <div className="flex w-full items-center justify-between space-x-6 p-6">
                  <div className="flex-1 truncate">
                  <div className="flex items-center space-x-3">
                      <h3 className="truncate text-sm font-medium text-gray-900">{fineTune.title}</h3>
                  </div>
                  <div className="flex items-center space-x-3 mt-1">
                      <h3 className="truncate text-sm font-sm text-gray-700">{fineTune.base_model}</h3>
                  </div>
                  <p className="mt-1 truncate text-sm text-gray-500">{fineTune.file}</p>
                  </div>
              </div>
              <div>
                  <div className="-mt-px flex divide-x divide-gray-200">
                    <div className="-ml-px flex w-0 flex-1">
                      <a
                      href={`/models/${fineTune._id}`}
                      className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
                      >
                      <span className="mr-3">Details</span>
                      <ArrowLongRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
              </div>
              </li>
            ))}
          </ul>
          </div>
          )}
        </main>
      </div>
  )
}

export default Models;