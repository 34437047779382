import React, { createContext, useReducer, useEffect, useContext } from 'react';

export const ProjectContext = createContext();

export const projectReducer = (state, action) => {
    switch (action.type) {
        case 'CREATE_PROJECT':
            return {
                project: action.payload, ...state.project
            }
        default:
            return state;
    }
}

export const ProjectContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(projectReducer, {
        project: null
    });

    return (
        <ProjectContext.Provider value={{ state, dispatch }}>
            {children}
        </ProjectContext.Provider>
    )
}

export const useProjectContext = () => useContext(ProjectContext);