import { useAuthContext } from "./useAuthContext"
// import { useBountyContext } from "./useBountyContext"

export const useLogout = () => {
    const { dispatch } = useAuthContext()
    // const { dispatch: bountyDispatch } = useBountyContext()
    
    const logout = () => {
        // remove user from storage
        localStorage.removeItem('user')

        // dispatch logout function
        dispatch({type: 'LOGOUT'})
        // bountyDispatch({type: 'SET_BOUNTIES', payload: null})
    }

    return {logout}
}