import React, { useState } from 'react'
import NavbarTwo from '../components/NavbarTwo'
import { useAuthContext } from '../hooks/useAuthContext';

const UIStudio = () => {
    const { user } = useAuthContext();

    const [layout, setLayout] = useState("LayoutOne")
    const [primaryColor, setPrimaryColor] = useState("#4541FF")
    const [secondaryColor, setSecondaryColor] = useState("#b0b0b0")
    const [bgColor, setBgColor] = useState("#FFFFFF")
    const [mainTextColor, setMainTextColor] = useState("#000000")

    if (!user) {
      return (
        <div className="pt-20 w-full min-h-screen flex-2 text-center">
          <p className="mt-2 text-md font-medium text-gray-900">Not logged in</p>
          <h3 className="mt-2 text-sm font-medium text-gray-900">Please <a className='underline text-indigo-500' href='/login'>sign in to your account</a> or <a className='underline text-indigo-500' href='signup'>create a new account</a></h3>
        </div>
      )
    }

  return (
    <div className='w-full min-h-screen flex-2'>
        <NavbarTwo />
        <header className="bg-white shadow">
          <div className="mx-20 max-w-7xl py-6 px-4 sm:px-6">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">UI Studio</h1>
          </div>
        </header>
        <main>
            <div>
                <form>
                    <div className='flex flex-row'>
                    <div className="basis-1/4 text-black rounded-2xl p-3 m-3 bg-white">
                        <div className='py-3'>
                        <h3 className=" pb-3 text-lg font-medium leading-6 text-gray-900">Settings</h3>
                            <label className="block text-sm font-medium text-gray-700">
                            Layout Template
                            </label>
                            <select
                            onChange={(e) => setLayout(e.target.value)}
                            value={layout}
                            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            >
                            <option value={'LayoutOne'}>Layout #1</option>
                            <option value={'LayoutTwo'}>Layout #2</option>
                            </select>
                        </div>
                          <div>
                            <div className='py-3'>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Primary Color
                                </label>
                                <p className="block text-sm font-medium text-gray-700">{primaryColor}</p>
                                <div className="mt-1">
                                <input
                                    type="color"
                                    className="block rounded-md"
                                    value={primaryColor}
                                    onChange={(e) => setPrimaryColor(e.target.value)}

                                />
                                </div>
                            </div>
                            <div className='py-3'>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Secondary Color
                                </label>
                                <p className="block text-sm font-medium text-gray-700">{secondaryColor}</p>
                                <div className="mt-1">
                                    <input
                                    type="color"
                                    className="block rounded-md"
                                    value={secondaryColor}
                                    onChange={(e) => setSecondaryColor(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='py-3'>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Background Color
                                </label>
                                <p className="block text-sm font-medium text-gray-700">{bgColor}</p>
                                <div className="mt-1">
                                <input
                                    type="color"
                                    className="block rounded-md"
                                    value={bgColor}
                                    onChange={(e) => setBgColor(e.target.value)}

                                />
                                </div>
                            </div>
                            <div className='py-3'>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Main Text Color
                                </label>
                                <p className="block text-sm font-medium text-gray-700">{mainTextColor}</p>
                                <div className="mt-1">
                                <input
                                    type="color"
                                    className="block rounded-md"
                                    value={mainTextColor}
                                    onChange={(e) => setMainTextColor(e.target.value)}
                                />
                                </div>
                            </div>
                          </div>
                        </div>
                        <div className="basis-3/4 text-black bg-white rounded-2xl p-3 m-3">
                            {layout === 'LayoutOne' ? (
                                <div
                                style={{backgroundColor: bgColor}}
                                className="flex rounded-md min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
                                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                                        <h2 style={{color: mainTextColor}} className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Title</h2>
                                        <p style={{color: mainTextColor}} className="mt-2 text-center text-sm text-gray-600">Description</p>
                                    </div>
                                    
                                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                                        <div style={{backgroundColor: bgColor}} className=" py-8 px-4 rounded-md shadow sm:rounded-lg sm:px-10">
                                        <form className="space-y-6" >
                                            <div style={{borderBlockColor: secondaryColor}}>
                                            <div className="mt-1">
                                                <textarea
                                                rows={3}
                                                style={{backgroundColor: bgColor, color: mainTextColor}}
                                                onFocus={e => {
                                                    e.target.style.borderColor = secondaryColor;
                                                    }}
                                                onBlur={e => {
                                                e.target.style.borderColor = bgColor;
                                                }}

                                                className="block rounded-md w-full appearance-none  border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
                                                placeholder="Prompt for user input"
                                                />
                                            </div>
                                            </div>
                            
                                            <div>
                                            <button
                                                type="submit"
                                                style={{backgroundColor: primaryColor}}
                                                className="flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                Go
                                            </button>
                                            </div>
                                        </form>
                            
                                        <div style={{color: mainTextColor}} className="mt-6">
                                            <div className="relative">
                                            <div className="relative flex justify-center text-sm">
                                                <p className="px-2 text-gray-400">// result prints here</p>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <p className='justify-center relative flex py-5'><a style={{color: mainTextColor}} className='underline' href="/" target="_blank">Create your own AI-powered app with Aspen</a></p>
                                </div>
                            ) : layout === 'LayoutTwo' ? (
                                <div
                                style={{backgroundColor: bgColor}}
                                className="flex min-h-full rounded-md flex-col justify-center py-12 sm:px-6 lg:px-8">
                                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                                      <h2 style={{color: mainTextColor}} className="mt-6 text-center text-3xl font-bold tracking-tight">Title</h2>
                                      <p style={{color: mainTextColor}} className="mt-2 text-center text-sm ">Description</p>
                                    </div>
                                <div className="min-w-0 flex-1 sm:mx-auto sm:w-full sm:max-w-md mt-20">
                                  <form >
                                    <div 
                                    style={{borderBlockColor: secondaryColor}}
                                    className="border-b border-gray-200">
                                      <textarea
                                        rows={3}
                                        style={{backgroundColor: bgColor, color: mainTextColor}}
                                        onFocus={e => {
                                            e.target.style.borderColor = secondaryColor;
                                            }}
                                        onBlur={e => {
                                        e.target.style.borderColor = bgColor;
                                        }}
                                        className="block w-full rounded-md resize-noneborder-0 border-transparent pb-2 focus:ring-0 sm:text-sm"
                                        placeholder="Prompt for user input"
                                        defaultValue={''}
                                      />
                                    </div>
                                    <div className="flex justify-between p-2">
                                      <div className="flex-shrink-0">
                                        <button
                                          type="submit"
                                          style={{backgroundColor: primaryColor}}
                                          className="inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                          Go
                                        </button>
                                      </div>
                                    </div>
                                    <div style={{color: mainTextColor}} className="pt-10 text-sm">
                                    </div>
                                  </form>
                                </div>
                              </div>
                            ) : null}
                        </div>
                    </div>
                </form>
            </div>
        </main>
    </div>
  )
}

export default UIStudio