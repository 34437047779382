import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import NavbarTwo from '../components/NavbarTwo';
import { useParams } from 'react-router-dom'


const ProjectSettings = () => {
    const { user } = useAuthContext();
    const [model, setModel] = useState({})
    const [loading, setLoading] = useState(false)
    const [fineTuneOpenAIData, setFineTuneOpenAIData] = useState({})
    const [deleted, setDeleted] = useState(false)
  
    const params = useParams()
    const id = params.id

    useEffect(() => {
        setLoading(true)
        const fetchProject = async () => {
            const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/finetune/getfinetunerecord/' + id, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
            })

            const json = await response.json()

            const fetchOpenAIData = async () => {
                const openAIResponse = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/finetune/getfinetunefromopenai/' + model.openAIModelID, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
                })

                const openAIData = await openAIResponse.json()

                if (openAIResponse.ok) {
                setFineTuneOpenAIData(openAIData)
                }
            }
          
          if (response.ok) {
            setModel(json)

            if (model.openAIModelID !== undefined) {
                fetchOpenAIData()
            }
          }

          if (!response.ok) {
            console.log('error')
          }
          setLoading(false)
        }

        if (user) {
            fetchProject()
        }
      }, [user, id, model.openAIModelID])

      const handleDelete = async () => {
        if (!user) {
          return
        }
    
        if (!window.confirm('Are you sure you want to delete this fine tune?')) {
          return
        }
    
        setLoading(true)
        console.log(model._id)
    
        const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/finetune/delete/' + model._id, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        })
          
        const json = await response.json()
    
        if (response.ok) {
          console.log('fine tune record deleted')
          setDeleted(true)
        }
    
        if (!response.ok) {
          console.log({'error': json})
        }
    
        // delete fine tune on openai
    
        // delete file on openai
    
        // delete record
    
        setLoading(false)
      }

    if (!user) {
        return (
          <div className="pt-20 w-full min-h-screen flex-2 text-center">
            <p className="mt-2 text-md font-medium text-gray-900">Not logged in</p>
            <h3 className="mt-2 text-sm font-medium text-gray-900">Please <a className='underline text-indigo-500' href='/login'>sign in to your account</a> or <a className='underline text-indigo-500' href='signup'>create a new account</a></h3>
          </div>
        )
    }

    return (
        <div className='w-full min-h-screen flex-2'>
            <NavbarTwo />
            <header className="bg-white shadow">
                <div className="mx-20 max-w-7xl py-6 px-4 sm:px-6">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900">Fine Tune</h1>
                    <p className='text-gray-700 font-medium tracking-light text-sm pt-2'>{model._id}</p>
                </div>
            </header>
            <>
            {loading ? (
                <div className="pt-20 w-full min-h-screen flex-2 text-center">
                    <p className="mt-2 text-md font-medium text-gray-900">Loading...</p>
                </div>
            ) : (
                <main className='mx-20 py-6 sm:px-6'>
                <div>
                    <div className="mt-5 md:mt-0">
                        <div className="shadow sm:overflow-hidden sm:rounded-md">
                            <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-3 gap-6">
                                    <div className="col-span-3 sm:col-span-2">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Title
                                        </label>
                                        <div className="mt-1 flex">
                                            <p className="mt-2 text-sm text-gray-700">{model.title}</p>
                                        </div>
                                    </div>
                                </div>

                                {fineTuneOpenAIData.fine_tuned_model != null ? (
                                <div className="grid grid-cols-3 gap-6">
                                    <div className="col-span-3 sm:col-span-2">
                                        <label className="block text-sm font-medium text-gray-700">
                                            OpenAI Model Name
                                        </label>
                                        <div className="mt-1 flex">
                                            <p className="mt-2 text-sm text-gray-700">{fineTuneOpenAIData.fine_tuned_model}</p>
                                        </div>
                                    </div>
                                </div>
                                ) : null}

                                <div className="grid grid-cols-3 gap-6">
                                    <div className="col-span-3 sm:col-span-2">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Base Model
                                        </label>
                                        <div className="mt-1 flex">
                                            <p className="mt-2 text-sm text-gray-700">{model.base_model}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-6">
                                    <div className="col-span-3 sm:col-span-2">
                                        <label className="block text-sm font-medium text-gray-700">
                                            File
                                        </label>
                                        <div className="mt-1 flex">
                                            <p className="mt-2 text-sm text-gray-700">{model.file}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-6">
                                    <div className="col-span-3 sm:col-span-2">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Status
                                        </label>
                                        <div className="mt-1 flex">
                                            <p className="mt-2 text-sm text-gray-700">{fineTuneOpenAIData.status}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 gap-6">
                                    <div className="col-span-3 sm:col-span-2">
                                        <label className="block text-sm font-medium text-gray-700">
                                            User ID
                                        </label>
                                        <div className="mt-1 flex">
                                            <p className="mt-2 text-sm text-gray-700">{model.user_id}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!deleted ? (
                        <button
                            onClick={handleDelete}
                            type="button"
                            className="mt-2 mb-2 inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        >
                            Delete
                        </button>
                        ) : (
                        <p>Fine tune successfully deleted</p>
                        )}
                    </div>
                </div>
            </main>
            )}
            </>
        </div>
    )
  }
  

  export default ProjectSettings