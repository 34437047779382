import React, {useState } from 'react'

let messages = [
    {"role": "system", "content": "You are the leader of a highly effective sales team."},
]

const Proposal = () => {
    const [loading, setLoading] = useState(false)
    const [result, setResult] = useState('')
    const [companyInfo, setCompanyInfo] = useState("PixelSoft is a fast-growing software company that specializes in developing cutting-edge mobile applications for various platforms. With a team of highly skilled engineers and designers, PixelSoft has gained a reputation for delivering exceptional software solutions to its clients. However, the company is currently facing an internal problem with its outbound sales strategy. The sales team has been struggling to generate new leads and close deals with potential clients. Despite having a solid product offering, the sales team has been unable to effectively communicate the value proposition of the company's software to prospective customers. PixelSofts management team is now seeking ways to revamp their outbound sales strategy to better align with customer needs and improve sales performance.")
    const [productInfo, setProductInfo] = useState("LeadsGenius is a software company that specializes in outbound sales automation. Their product, SalesBots, is an AI-powered sales platform that helps companies  generate more leads and close more deals. SalesBots uses advanced algorithms to analyze customer data and identify the most promising leads. It then automates the sales process by sending personalized emails and messages to these leads, based on their behavior and interests. SalesBots also provides real-time analytics and reporting, so that companies can track the performance of their outbound sales campaigns and make data-driven decisions.")
    const [proposalInfo, setProposalInfo] = useState("The proposal should be roughly 50 words and specific to the client's needs. Make sure it is in bullet form.")

    async function handleSubmit(e) {
        e.preventDefault()
        setResult('')
        setLoading(true)
        messages.push({"role": "user", "content": "I am looking for a sales proposal for a client. " + proposalInfo + " Here is the client's information: " + companyInfo + ". Here is the product information: " + productInfo})
        const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/chat', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ messages: messages })
        })
        const json = await response.json()
        setLoading(false)
        setResult(json.content)
        console.log(json.content)
    }

  return (
    <div>
        <div className="p-2 sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Sales Proposal Generator</h2>
            <p className="mt-2 text-center text-md text-gray-600">Powered by Aspen</p>
        </div>
        
        <div className='flex flex-row'>
            <div className="basis-2/5 text-black rounded-2xl h-full p-3 m-3 bg-white">
            <div>
            <div className="py-6 px-4 rounded-md sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                <h2 className="text-xl font-bold text-gray-900">Inputs</h2>

                <div className="mt-1">
                    <label htmlFor="company" className="pb-2 block text-md font-medium text-gray-700">
                    Target Information
                    </label>
                    <textarea
                    rows={8}
                    className="block rounded-md w-full appearance-none  border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm"
                    placeholder="Prompt for user input"
                    value={companyInfo}
                    onChange={(e) => setCompanyInfo(e.target.value)}
                    />
                </div>
                <div className="mt-5">
                    <label htmlFor="company" className="pb-2 block text-md font-medium text-gray-700">
                    Products / Services Offered
                    </label>
                    <textarea
                    rows={8}
                    className="block rounded-md w-full appearance-none  border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm"
                    placeholder="Prompt for user input"
                    value={productInfo}
                    onChange={(e) => setProductInfo(e.target.value)}
                    />
                </div>
                <div className="mt-5">
                    <label htmlFor="company" className="pb-2 block text-md font-medium text-gray-700">
                    Proposal Requirements
                    </label>
                    <textarea
                    rows={4}
                    className="block rounded-md w-full appearance-none  border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm"
                    placeholder="The proposal should be..."
                    value={proposalInfo}
                    onChange={(e) => setProposalInfo(e.target.value)}
                    />
                </div>
                </div>

                <div>
                <button
                    type="submit"
                    disabled={loading}
                    className="flex disabled:bg-indigo-300 disabled:cursor-not-allowed w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium bg-indigo-600 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                    {loading ? ( <p>Loading...</p>) : ( <p>Generate Proposal</p>)}
                </button>
                </div>
            </form>
            </div>
        </div>
            </div>
            <div className="basis-3/5 text-black rounded-2xl p-3 m-3 bg-white">
                <div className="mt-6 mx-5">
                <h2 className="text-xl font-bold text-gray-900">Sales Proposal</h2>
                    <div className="mt-1 relative">
                    <div className="relative flex text-md">
                    {result ? (
                        <p className='whitespace-pre-wrap'>{result}</p>
                    ) : loading ? (
                        <div class="py-4 w-full mx-auto">
                        <div class="animate-pulse flex space-x-4">
                          <div class="flex-1 space-y-6 py-1">
                            <div class="h-2 bg-slate-700 rounded"></div>
                            <div class="space-y-3">
                              <div class="grid grid-cols-3 gap-4">
                                <div class="h-2 bg-slate-700 rounded col-span-2"></div>
                                <div class="h-2 bg-slate-700 rounded col-span-1"></div>
                              </div>
                              <div class="h-2 bg-slate-700 rounded"></div>
                            </div>
                            <div class="space-y-3">
                              <div class="grid grid-cols-4 gap-4">
                                <div class="h-2 bg-slate-700 rounded col-span-1"></div>
                                <div class="h-2 bg-slate-700 rounded col-span-3"></div>
                              </div>
                              <div class="h-2 bg-slate-700 rounded"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                        <p className="text-gray-500">Result prints here</p>
                    )}
                    </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
  
  )
}

export default Proposal