import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useAuthContext } from './hooks/useAuthContext';

import './App.css';
import StudioTwo from './pages/StudioTwo';
import Sandbox from './pages/Sandbox';
import Dashboard from './pages/Dashboard';
import Projects from './pages/Projects';
import Settings from './pages/Settings';
import ProjectBuilder from './pages/ProjectBuilder';
import DeployedProject from './pages/DeployedProject';
import Login from './pages/auth/Login';
import Signup from './pages/auth/Signup';
import ProjectSettings from './pages/ProjectSettings';
import SandboxTwo from './pages/SandboxTwo';
import ModelFineTuning from './pages/ModelFineTuning';
import Models from './pages/Models';
import FineTune from './pages/FineTune';
import ChangePassword from './pages/auth/ChangePassword';
import RequestPasswordReset from './pages/auth/RequestPasswordReset';
import Plans from './pages/payments/Plans';
import Willow from './pages/Chat';
import TestDeployedProject from './pages/TestDeployedProject';
import Users from './pages/internal/Users';
import User from './pages/internal/User';
import UIStudio from './pages/UIStudio';
import Proposal from './pages/projects/sales/proposal';
import Email from './pages/projects/admin/email';
import AllProjects from './pages/internal/AllProjects';
import NewProject from './pages/NewProject';
import CreateChatbot from './pages/CreateChatbot';
import ChatProjects from './pages/ChatProjects';
import DeployedChatbot from './pages/DeployedChatbot';
import '../node_modules/highlight.js/styles/magula.css';


const App = () => {
  const { user } = useAuthContext();


  return (
    <div className='light'>
      <BrowserRouter>
        <div className="flex bg-gray-100 relative">
          <div
            className='w-full min-h-screen flex-2 '>
            <div>
              <Routes>
                <Route path="/" element={!user ? <Signup /> : <Navigate to='/dashboard' />} />
                <Route path="/sandbox" element={<Sandbox />} />
                <Route path="/sandboxtwo" element={<SandboxTwo />} />
                <Route path="/studio" element={<StudioTwo />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/projectbuilder" element={<ProjectBuilder />} />
                <Route path="/projects/:id" element={<DeployedProject />} />
                <Route path="/login" element={!user ? <Login /> : <Navigate to="/dashboard" />} />
                <Route path="/signup" element={!user ? <Signup /> : <Navigate to="/dashboard" />} />
                <Route path="/projectsettings/:id" element={<ProjectSettings />} />
                <Route path="/modelfinetuning" element={<ModelFineTuning />} />
                <Route path="/models" element={<Models />} />
                <Route path="/models/:id" element={<FineTune />} />
                <Route path="/changepassword/:userID/:token" element={<ChangePassword />} />
                <Route path="/requestpasswordreset" element={<RequestPasswordReset />} />
                <Route path="/plans" element={<Plans />} />
                <Route path="/willow" element={<Willow />} />
                <Route path="/testdeployedproject/:id" element={<TestDeployedProject />} />
                <Route path="/newproject" element={<NewProject />} />
                <Route path="/createchatbot" element={<CreateChatbot />} />
                <Route path="/mychatbots" element={<ChatProjects />} />
                <Route path="/chatbot/:id" element={<DeployedChatbot />} />
                
                <Route path="/internal/users" element={<Users />} />
                <Route path="/internal/user/:id" element={<User />} />
                <Route path="/internal/allprojects" element={<AllProjects />} />

                <Route path="/uistudio" element={<UIStudio />} />
                <Route path="/projects/sales/proposal" element={<Proposal />} />
                <Route path="/projects/admin/email" element={<Email />} />
              </Routes>
            </div>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
