import React, { useState } from 'react';
import { Switch } from '@headlessui/react'

// let examples = [
//     {
//         inputTitle: "Input title",
//         input: "This is the command that the user will input when they use your app",
//         outputTitle: "Output title",
//         output: "This is the prompt that the model will use to respond to the command",
//     },
//     {
//         inputTitle: "Input title",
//         input: "This is the command that the user will input when they use your app",
//         outputTitle: "Output title",
//         output: "This is the prompt that the model will use to respond to the command",
//     },
//     {
//         inputTitle: "Input title",
//         input: "This is the command that the user will input when they use your app",
//         outputTitle: "Output title",
//         output: "This is the prompt that the model will use to respond to the command",
//     },
//     {
//         inputTitle: "Input title",
//         input: "This is the command that the user will input when they use your app",
//         outputTitle: "Output title",
//         output: "This is the prompt that the model will use to respond to the command",
//     }
// ]

export default function SandboxComponentTwo() {

    const [loading, setLoading] = useState(false);
    const [enabled, setEnabled] = useState(false)

    const [prompt, setPrompt] = useState('Write a few examples of how you would like the program to behave')
    const [command, setCommand] = useState("This is the command that the user will input when they use your app")
    const [end, setEnd] = useState('This is the prompt that the model will use to respond to the command')
    const [result, setResult] = useState("");
    
    // const [inputTitle, setInputTitle] = useState(examples[0].inputTitle)

    const [inputTitle, setInputTitle] = useState("Input title")
    const [input1, setInput1] = useState("")
    const [outputTitle, setOutputTitle] = useState("Output title")
    const [output1, setOutput1] = useState("")

    const [input2, setInput2] = useState("")
    const [output2, setOutput2] = useState("")

    const [input3, setInput3] = useState("")
    const [output3, setOutput3] = useState("")

    const [input4, setInput4] = useState("")
    const [output4, setOutput4] = useState("")

    const [userInput, setUserInput] = useState("")

    const [modelUsed, setModelUsed] = useState("text-davinci-003")
    const [maxTokens, setMaxTokens] = useState(500)
    const [temperature, setTemperature] = useState(0.7)
    const [topP, setTopP] = useState(1)
    const [frequencyPenalty, setFrequencyPenalty] = useState(0)
    const [presencePenalty, setPresencePenalty] = useState(0)
    


    async function handleSubmit(e) {
        e.preventDefault()

        const finalPrompt = inputTitle + ": " + input1 + "\n\n" + outputTitle + ": " + output1 + "\n\n" + inputTitle + ": " + input2 + "\n\n" + outputTitle + ": " + output2 + "\n\n" + inputTitle + ": " + input3 + "\n\n" + outputTitle + ": " + output3 + "\n\n" + inputTitle + ": " + input4 + "\n\n" + outputTitle + ": " + output4 + "\n"

        setLoading(true)
        const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/generalopenai', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({prompt: finalPrompt, command: inputTitle + ": " + userInput, end: outputTitle + ": ", modelUsed, maxTokens, temperature, topP, frequencyPenalty, presencePenalty}),
        })
        const json = await response.json()
        setLoading(false)
        setResult(json)
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
        }

  return (
    <div>
        <form onSubmit={handleSubmit}>
            <div className='flex flex-row'>
            <div className="basis-1/4 text-black rounded-2xl p-3 m-3 bg-white">
                <div className='py-3'>
                <h3 className=" pb-3 text-lg font-medium leading-6 text-gray-900">Settings (2)</h3>
                    <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                    Model
                    </label>
                    <select
                    onChange={(e) => setModelUsed(e.target.value)}
                    value={modelUsed}
                    id="model"
                    name="model"
                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    >
                    <option value={'text-davinci-003'}>text-davinci-003</option>
                    <option value={'text-curie-001'}>text-curie-001</option>
                    <option value={'text-babbage-001'}>text-babbage-001</option>
                    <option value={'text-ada-001'}>text-ada-001</option>
                    </select>
                </div>
                <Switch.Group as="div" className="pt-5 flex items-center justify-between">
                    <span className="flex flex-grow flex-col">
                        <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                        Advanced Settings
                        </Switch.Label>
                    </span>
                    <Switch
                        checked={enabled}
                        onChange={setEnabled}
                        className={classNames(
                        enabled ? 'bg-indigo-600' : 'bg-gray-200',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                        )}
                    >
                        <span
                        aria-hidden="true"
                        className={classNames(
                            enabled ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                        />
                    </Switch>
                </Switch.Group>
                {enabled? (
                    <div>
                    <div className='py-3'>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Max Tokens
                        </label>
                        <div className="mt-1">
                        <input
                            type="number"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            value={maxTokens}
                            onChange={(e) => setMaxTokens(e.target.value)}
                        />
                        </div>
                    </div>
                    <div className='py-3'>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Temperature
                    </label>
                    <div className="mt-1">
                        <input
                        type="number"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="0.1"
                        value={temperature}
                        onChange={(e) => setTemperature(e.target.value)}
                        />
                    </div>
                    </div>
                    <div className='py-3'>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Top_P
                    </label>
                    <div className="mt-1">
                        <input
                        type="number"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        value={topP}
                        onChange={(e) => setTopP(e.target.value)}
                        />
                    </div>
                    </div>
                    <div className='py-3'>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Frequency Penalty
                    </label>
                    <div className="mt-1">
                        <input
                        type="number"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        value={frequencyPenalty}
                        onChange={(e) => setFrequencyPenalty(e.target.value)}
                        />
                    </div>
                    </div>
                    <div className='py-3'>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Presence Penalty
                    </label>
                    <div className="mt-1">
                        <input
                        type="number"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="0.1"
                        value={presencePenalty}
                        onChange={(e) => setPresencePenalty(e.target.value)}
                        />
                    </div>
                    </div>
                    </div>
                ) : null}
                </div>
                <div className="basis-3/4 text-black bg-white rounded-2xl p-3 m-3">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Examples</h3>
                    <div className="pt-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-6 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <div className="mt-1 sm:col-span-1 sm:mt-0">
                                <div className="flex max-w-full rounded-md shadow-sm">
                                <textarea
                                    value={inputTitle}
                                    onChange={(e) => setInputTitle(e.target.value)}
                                    rows={1}
                                    type="text"
                                    placeholder="Input Title"
                                    className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                </div>
                            </div>
                            <div className="mt-1 sm:col-span-5 sm:mt-0">
                                <div className="flex max-w-full rounded-md shadow-sm">
                                <textarea
                                    value={input1}
                                    onChange={(e) => setInput1(e.target.value)}
                                    rows={1}
                                    type="text"
                                    placeholder="User Input"
                                    className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                </div>
                            </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-6 sm:items-start sm:gap-4 sm:border-gray-200">
                            <div className="mt-1 sm:col-span-1 sm:mt-0">
                                <div className="flex max-w-full rounded-md shadow-sm">
                                <textarea
                                    value={outputTitle}
                                    onChange={(e) => setOutputTitle(e.target.value)}
                                    rows={1}
                                    type="text"
                                    placeholder="Output Title"
                                    className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                </div>
                            </div>
                            <div className="mt-1 sm:col-span-5 sm:mt-0">
                                <div className="flex max-w-full rounded-md shadow-sm">
                                <textarea
                                    value={output1}
                                    onChange={(e) => setOutput1(e.target.value)}
                                    rows={1}
                                    type="text"
                                    placeholder="Model Output"
                                    className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pt-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-6 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            {inputTitle ? inputTitle : "Input"}
                            </label>
                            <div className="mt-1 sm:col-span-5 sm:mt-0">
                                <div className="flex max-w-full rounded-md shadow-sm">
                                <textarea
                                    value={input2}
                                    onChange={(e) => setInput2(e.target.value)}
                                    rows={1}
                                    type="text"
                                    placeholder="User Input"
                                    className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                </div>
                            </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-6 sm:items-start sm:gap-4 sm:border-gray-200">
                            <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            {outputTitle ? outputTitle : "Output"}
                            </label>
                            <div className="mt-1 sm:col-span-5 sm:mt-0">
                                <div className="flex max-w-full rounded-md shadow-sm">
                                <textarea
                                    value={output2}
                                    onChange={(e) => setOutput2(e.target.value)}
                                    rows={1}
                                    type="text"
                                    placeholder="Model Output"
                                    className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pt-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-6 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            {inputTitle ? inputTitle : "Input"}
                            </label>
                            <div className="mt-1 sm:col-span-5 sm:mt-0">
                                <div className="flex max-w-full rounded-md shadow-sm">
                                <textarea
                                    value={input3}
                                    onChange={(e) => setInput3(e.target.value)}
                                    rows={1}
                                    type="text"
                                    placeholder="User Input"
                                    className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                </div>
                            </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-6 sm:items-start sm:gap-4 sm:border-gray-200">
                            <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            {outputTitle ? outputTitle : "Output"}
                            </label>
                            <div className="mt-1 sm:col-span-5 sm:mt-0">
                                <div className="flex max-w-full rounded-md shadow-sm">
                                <textarea
                                    value={output3}
                                    onChange={(e) => setOutput3(e.target.value)}
                                    rows={1}
                                    type="text"
                                    placeholder="Model Output"
                                    className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-6 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            {inputTitle ? inputTitle : "Input"}
                            </label>
                            <div className="mt-1 sm:col-span-5 sm:mt-0">
                                <div className="flex max-w-full rounded-md shadow-sm">
                                <textarea
                                    value={input4}
                                    onChange={(e) => setInput4(e.target.value)}
                                    rows={1}
                                    type="text"
                                    placeholder="User Input"
                                    className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                </div>
                            </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-6 sm:items-start sm:gap-4 sm:border-gray-200">
                            <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            {outputTitle ? outputTitle : "Output"}
                            </label>
                            <div className="mt-1 sm:col-span-5 sm:mt-0">
                                <div className="flex max-w-full rounded-md shadow-sm">
                                <textarea
                                    value={output4}
                                    onChange={(e) => setOutput4(e.target.value)}
                                    rows={1}
                                    type="text"
                                    placeholder="Model Output"
                                    className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Final (What the user will see)</h3>
                    <div className="py-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-6 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            {inputTitle ? inputTitle : "Input"}
                            </label>
                            <div className="mt-1 sm:col-span-5 sm:mt-0">
                                <div className="flex max-w-full rounded-md shadow-sm">
                                <textarea
                                    value={userInput}
                                    onChange={(e) => setUserInput(e.target.value)}
                                    rows={1}
                                    type="text"
                                    placeholder="User Input"
                                    className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                                </div>
                            </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-6 sm:items-start sm:gap-4 sm:border-gray-200">
                            <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            {outputTitle ? outputTitle : "Output"}
                            </label>
                            <div className="mt-1 sm:col-span-5 sm:mt-0">
                                {result ? (
                                    <p className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">{result}</p>

                                ) : loading ? (
                                    <p className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Loading...</p>
                                ) : (
                                    <p className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">// output prints here</p>

                                )}
                            </div>
                        </div>
                    </div>

                    
                    {/* <div className="bg-white appearance-none rounded-md relative block
                        w-full border border-gray-300
                        placeholder-gray-500 text-gray-900
                        focus:outline-none  focus:z-10 sm:text-sm">
                        <textarea 
                            name="description"
                            type="text"
                            value={prompt}
                            onChange={(e) => setPrompt(e.target.value)}
                            rows={25}
                            autoComplete="on"
                            className="appearance-none rounded-md relative block
                            w-full px-3 py-2 border border-gray-300
                            placeholder-gray-500 text-gray-900
                            focus:outline-none  focus:z-10 sm:text-sm"
                            placeholder='Enter text here'
                        />
                    </div> */}
                    {/* <h3 className="pt-3 pb-3 text-lg font-medium leading-6 text-gray-900">Command</h3>
                    <div className="bg-white appearance-none rounded-md relative block
                        w-full border border-gray-300
                        placeholder-gray-500 text-gray-900
                        focus:outline-none  focus:z-10 sm:text-sm">
                        <textarea 
                            name="description"
                            type="text"
                            value={command}
                            onChange={(e) => setCommand(e.target.value)}
                            rows={3}
                            autoComplete="on"
                            className="appearance-none rounded-md relative block
                            w-full px-3 py-2 border border-gray-300
                            placeholder-gray-500 text-gray-900
                            focus:outline-none  focus:z-10 sm:text-sm"
                            placeholder='Enter text here'
                        />
                    </div> */}
                    {/* <h3 className="pt-3 pb-3 text-lg font-medium leading-6 text-gray-900">End</h3>
                    <div className="bg-white appearance-none rounded-md relative block
                        w-full border border-gray-300
                        placeholder-gray-500 text-gray-900
                        focus:outline-none  focus:z-10 sm:text-sm">
                        <textarea 
                            name="description"
                            type="text"
                            value={end}
                            onChange={(e) => setEnd(e.target.value)}
                            rows={1}
                            autoComplete="on"
                            className="appearance-none rounded-md relative block
                            w-full px-3 py-2 border border-gray-300
                            placeholder-gray-500 text-gray-900
                            focus:outline-none  focus:z-10 sm:text-sm"
                            placeholder='Enter text here'
                        />
                        </div> */}
                    {/* <div className="pt-3 flex">
                        <div className="w-1/2">
                            <button className="float-left py-2 border border-transparent text-sm
                            rounded-md text-black hover:bg-sky-500
                            focus:outline-none focus:ring-2 focus:ring-offset-2
                            focus:ring-indigo-500">
                                Add Example
                            </button>
                        </div>
                    </div> */}
                    <div className="pt-3 flex">
                        <div className="w-1/2">
                            <button className="w-20 float-left py-2 px-4 mr-3 border border-transparent text-sm font-medium
                            rounded-md text-white bg-purple-700 hover:bg-sky-500
                            focus:outline-none focus:ring-2 focus:ring-offset-2
                            focus:ring-indigo-500">
                                Run
                            </button>
                        </div>
                    </div>
                </div>
                {/* <div className="basis-1/4 text-black rounded-2xl p-3 m-3 bg-white">
                <div className='py-3'>
                <h3 className=" pb-3 text-lg font-medium leading-6 text-gray-900">Output</h3>

                    {loading ? (
                    <p>Loading...</p>
                    ) : result ? (
                    <p>{result}</p>
                    ) : (
                    <p className='text-gray-400'>// Result prints here</p>
                    )}
                </div>
                </div> */}
            </div>
        </form>
  </div>
  )
}