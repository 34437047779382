import React, { useState, useEffect } from 'react';
import { PlusIcon } from '@heroicons/react/20/solid'
import NavbarTwo from '../components/NavbarTwo';
import { useAuthContext } from '../hooks/useAuthContext';
import MyProjects from '../components/MyProjects';


const Projects = () => {
  const [zeroProjects, setZeroProjects] = useState(false);
  const [projects, setProjects] = useState([])
  const [loading, setLoading] = useState(false)
  const { user } = useAuthContext();


  useEffect(() => {
    const fetchProjects = async () => {
      setLoading(true)
      const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/projects/', {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })
      const json = await response.json()

      if (response.ok) {
        setProjects(json)
        if (json.length === 0) {
          setZeroProjects(true)
        }
      }
      setLoading(false)
  }
  if (user) {
    fetchProjects()
  }
  }, [user])


  if (!user) {
    return (
      <div className="pt-20 w-full min-h-screen flex-2 text-center">
        <p className="mt-2 text-md font-medium text-gray-900">Not logged in</p>
        <h3 className="mt-2 text-sm font-medium text-gray-900">Please <a className='underline text-indigo-500' href='/login'>sign in to your account</a> or <a className='underline text-indigo-500' href='signup'>create a new account</a></h3>
      </div>
    )
  }

  return (
      <div className='w-full min-h-screen flex-2'>
        <NavbarTwo />
        <header className="bg-white shadow">
          <div className="mx-20 max-w-7xl py-6 px-4 sm:px-6">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">Projects</h1>
          </div>
        </header>

        <main>
          
          {loading? (
            <p className="mx-20 py-6 sm:px-6 text-black">Loading...</p>
            ): zeroProjects ? (
                      <div className="mx-20 py-6 px-4 sm:px-6">
                      <div className="text-center">
                          <svg
                              className="mx-auto h-12 w-12 text-gray-400"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                          >
                              <path
                              vectorEffect="non-scaling-stroke"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                              />
                          </svg>
                          <h3 className="mt-2 text-sm font-medium text-gray-900">No projects</h3>
                          <p className="mt-1 text-sm text-gray-500">Get started by creating a new project.</p>
                          <div className="mt-6">
                              <a
                              href="/projectbuilder"
                              className="inline-flex items-center rounded-md border border-transparent bg-purple-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              >
                              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                              New Project
                              </a>
                          </div>
                      </div>
                    </div>
          ) : (
            <div className="mx-20 py-6 px-4 sm:px-6">
            <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
            {projects && projects.map((project) => (
              <MyProjects project={project} key={project._id} />
            ))}
          </ul>
          </div>
          )
            }

        </main>

      </div>
  )
}

export default Projects;