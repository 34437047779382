import React, { useState, useEffect } from 'react';
import NavbarTwo from '../../components/NavbarTwo';
import {
  CheckIcon,
} from '@heroicons/react/24/outline'
import { CheckCircleIcon } from '@heroicons/react/20/solid'



const features = [
  'Unlimited projects',
  'User authentication',
  'Stripe integrations',
  'Model fine-tuning',
  'Up to 1,000 API calls',
  'Premium support',
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ProductDisplay = () => (
  <section>
          <div>
        {/* Pricing section with single price and feature list */}
        <div>
          <div className="pb-16 xl:flex xl:items-center xl:justify-between">
            <div>
              <h1 className="text-4xl font-bold tracking-tight sm:text-5xl">
                <span className="text-gray-900">Everything you need for </span>
                <span className="text-blue-600"> $19 a month</span>
              </h1>
              <p className="mt-5 text-xl text-gray-500">
                Everything you need to build and scale your product.
              </p>
            </div>
            <form action="/api/stripe/create-checkout-session" method="POST">
              <input type="hidden" name="lookup_key" value="aspen-premium-test-a" />
              <button className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 py-3 px-5 text-base font-medium text-white hover:bg-indigo-700 sm:mt-10 sm:w-auto xl:mt-0"
                id="checkout-and-portal-button" type="submit">
                Get Started
              </button>
            </form>
          </div>
          <div className="border-t border-gray-200 pt-16 xl:grid xl:grid-cols-3 xl:gap-x-8">
            <div>
              <h2 className="text-lg font-semibold text-blue-600">Everything you need</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900">All-in-one platform</p>
              <p className="mt-4 text-lg text-gray-500">
                Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla
                nec. Urna, sed a lectus elementum blandit et.
              </p>
            </div>
            <div className="mt-4 sm:mt-8 md:mt-10 md:grid md:grid-cols-2 md:gap-x-8 xl:col-span-2 xl:mt-0">
              <ul role="list" className="divide-y divide-gray-200">
                {features.slice(0, 3).map((feature, featureIdx) => (
                  <li key={feature} className={classNames(featureIdx === 0 ? 'md:py-0 md:pb-4' : '', 'py-4 flex')}>
                    <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                    <span className="ml-3 text-base text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>
              <ul role="list" className="divide-y divide-gray-200 border-t border-gray-200 md:border-t-0">
                {features.slice(3).map((feature, featureIdx) => (
                  <li
                    key={feature}
                    className={classNames(featureIdx === 0 ? 'md:border-t-0 md:py-0 md:pb-4' : '', 'py-4 flex')}
                  >
                    <CheckIcon className="h-6 w-6 flex-shrink-0 text-green-500" aria-hidden="true" />
                    <span className="ml-3 text-base text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
  </section>
);

const SuccessDisplay = ({ sessionId }) => {
  return (
    <section>
          <div className="rounded-md bg-green-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-green-800">Order completed</h3>
          <div className="mt-2 text-sm text-green-700">
            <p>Subscription to premium plan successful.</p>
          </div>
          <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex">
            <form action="/create-portal-session" method="POST">
              <input
                type="hidden"
                id="session-id"
                name="session_id"
                value={sessionId}
              />
              <button
                id="checkout-and-portal-button"
                type="submit"
                className="rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                >
                Manage your billing information
              </button>
            </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

export default function Plans() {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  if (!success && message === '') {
    return (
        <div>
            <NavbarTwo />
            <header className="bg-white shadow">
            <div className="mx-20 max-w-7xl py-6 px-4 sm:px-6">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900">Plans</h1>
            </div>
            </header>
            <main className='mx-20 py-6 px-4 sm:px-6'>
                <div className="py-10">
                    <ProductDisplay />
                </div>
            </main>
        </div>
    );

  } else if (success && sessionId !== '') {
    return (
    <div>
      <NavbarTwo />
        <header className="bg-white shadow">
          <div className="mx-20 max-w-7xl py-6 px-4 sm:px-6">
              <h1 className="text-3xl font-bold tracking-tight text-gray-900">Plans</h1>
          </div>
        </header>
        <main className='mx-20 py-6 px-4 sm:px-6'>
            <div className="py-10">
            <SuccessDisplay sessionId={sessionId} />
            </div>
        </main>
    </div>
    );
  } else {
    return (
      <div>
      <NavbarTwo />
        <header className="bg-white shadow">
          <div className="mx-20 max-w-7xl py-6 px-4 sm:px-6">
              <h1 className="text-3xl font-bold tracking-tight text-gray-900">Plans</h1>
          </div>
        </header>
        <main className='mx-20 py-6 px-4 sm:px-6'>
            <div className="py-10">
            <Message message={message} />
            </div>
        </main>
    </div>
    )
  }
}
