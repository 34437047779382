import React from 'react';
import NavbarTwo from '../components/NavbarTwo';
import {
  ChatBubbleLeftIcon,
  BoltIcon
} from '@heroicons/react/24/outline'
import { ChevronRightIcon } from '@heroicons/react/20/solid'

const items = [
  {
    name: 'Chatbot',
    description: 'Create a chatbot trained on your data.',
    href: '/createchatbot',
    iconColor: 'bg-purple-700',
    icon: ChatBubbleLeftIcon,
  },
  {
    name: 'Agent',
    description: 'Coming soon.',
    href: '#',
    iconColor: 'bg-purple-700',
    icon: BoltIcon,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const NewProject = () => {
  return (
    <div className='w-full min-h-screen flex-2'>

      <NavbarTwo />

      <header className="bg-white shadow">
        <div className="mx-20 max-w-7xl pt-6 pb-3  px-4 sm:px-6">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">New Project</h1>
        </div>
      </header>

    <div className='mx-20 pb-3 px-4 sm:px-6 py-10'>
      <h2 className="text-base font-semibold leading-6 text-gray-900">Create a new project</h2>
      <p className="mt-1 text-sm text-gray-500">Choose from one of our options below</p>
      <ul className="mt-6 divide-y divide-gray-200 border-b border-t border-gray-200">
        {items.map((item, itemIdx) => (
          <li key={itemIdx}>
            <div className="group relative flex items-start space-x-3 py-4">
              <div className="flex-shrink-0">
                <span
                  className={classNames(item.iconColor, 'inline-flex h-10 w-10 items-center justify-center rounded-lg')}
                >
                  <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <div className="min-w-0 flex-1">
                <div className="text-sm font-medium text-gray-900">
                  <a href={item.href}>
                    <span className="absolute inset-0" aria-hidden="true" />
                    {item.name}
                  </a>
                </div>
                <p className="text-sm text-gray-500">{item.description}</p>
              </div>
              <div className="flex-shrink-0 self-center">
                <ChevronRightIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
    </div>
  )
}
 
export default NewProject