import React, { useState, useEffect } from 'react'
import { RadioGroup, Switch } from '@headlessui/react'
import { useProjectContext } from '../contexts/projectContext'
import NavbarTwo from '../components/NavbarTwo'
import { useAuthContext } from '../hooks/useAuthContext';


const inputSettings = [
    { name: 'Text', description: 'Generative commands, such as "write a poem about love"', active: true },
    { name: 'File', description: 'Coming soon!', active: false },
    { name: 'Image', description: 'Coming soon!', active: false },
]

const outputSettings = [
  { name: 'Text', description: 'Generative commands, such as "write a poem about love"', active: true },
  { name: 'File', description: 'Coming soon!', active: false },
  { name: 'Image', description: 'Coming soon!', active: false },
]

const aiSettings = [
  { name: 'Configure with playground', active: true, boolean: false },
  { name: 'Use one of my fine tuned models', active: true, boolean: true },
]

const authSettings = [
  { name: 'No Authentication', value: false, description: 'Users will not be required to login to use my project.', active: true },
  { name: 'Authentication', value: true, description: 'Coming soon!', active: false },
]

const monetizationSettings = [
    { name: 'No Monetization', description: 'My project will be free to use.', active: true },
    { name: 'Monthly Fee', description: 'Coming soon!', active: false },
    { name: 'Pay-Per-Use', description: 'Coming soon!', active: false },
    { name: 'Credits', description: 'Coming soon!', active: false },
]

const files = [
  {
    title: 'Layout #1',
    layout: 'LayoutOne',
    source: require('../images/LayoutOne.png'),
  },
  {
    title: 'Layout #2',
    layout: 'LayoutTwo',
    source: require('../images/LayoutTwo.png'),
  }
]

const domainSettings = [
  { name: 'Use Aspen domain', description: 'For example: app.getaspenai.com/7a9n12j71b1923b10321h', active: true },
  { name: 'Add a custom domain', description: 'Coming soon!', active: false },
]


const ProjectBuilder = () => {
    const { dispatch } = useProjectContext();
    const [projectID, setProjectID] = useState('')
    const [selectedInput, setSelectedInput] = useState(inputSettings[0])
    const [selectedOutput, setSelectedOutput] = useState(outputSettings[0])
    const [selectedAuth, setSelectedAuth] = useState(authSettings[0])
    const [selectedMonetization, setSelectedMonetization] = useState(monetizationSettings[0])
    const [selectedDomain, setSelectedDomain] = useState(domainSettings[0])
    const [enabled, setEnabled] = useState(false)
    const [selectedFrontend, setSelectedFrontend] = useState('LayoutOne')
    const [successfulDeploy, setSuccessfulDeploy] = useState(false)

    const [viewProjectSettings, setViewProjectSettings] = useState(true)
    const [viewConfigureAI, setViewConfigureAI] = useState(false)
    const [viewSelectFrontend, setViewSelectFrontend] = useState(false)
    const [viewAdmin, setViewAdmin] = useState(false)
    const [viewDeploy, setViewDeploy] = useState(false)
    const [progressBarWidth, setProgressBarWidth] = useState('5.5%')

    const [title, setTitle] = useState('My Project One')
    const [description, setDescription] = useState('Enter a description of your project.')
    const [modelUsed, setModel] = useState('text-davinci-003')
    const [prompt, setPrompt] = useState('Write a few examples of how you would like the program to behave')
    const [command, setCommand] = useState("This is the command that the user will input when they use your app")
    const [end, setEnd] = useState('This is the prompt that the model will use to respond to the command')
    const [maxTokens, setMaxTokens] = useState(500)
    const [temperature, setTemperature] = useState(0.7)
    const [topP, setTopP] = useState(1)
    const [frequencyPenalty, setFrequencyPenalty] = useState(0)
    const [presencePenalty, setPresencePenalty] = useState(0)
    
    const [result, setResult] = useState("");
    const [loading, setLoading] = useState(false);
    const { user } = useAuthContext();
    const [fineTune, setFineTune] = useState(false);
    const [fineTunes, setFineTunes] = useState([]);
    const [zeroFineTunes, setZeroFineTunes] = useState(true);

    const [layout, setLayout] = useState("LayoutOne")
    const [primaryColor, setPrimaryColor] = useState("#4541FF")
    const [secondaryColor, setSecondaryColor] = useState("#b0b0b0")
    const [bgColor, setBgColor] = useState("#FFFFFF")
    const [mainTextColor, setMainTextColor] = useState("#000000")

    function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
    }

    async function createProject(e) {
      e.preventDefault()
      console.log(selectedInput)
      const inputFormat = selectedInput.name
      const outputFormat = selectedOutput.name
      const fineTuned = fineTune

      const project = {title, description, inputFormat, outputFormat, fineTuned}

      if (inputFormat != 'Text' || outputFormat != 'Text') {
        alert('Please select a valid input and output format.')
        return
      }

      console.log('Creating new project...')
      const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/projects/createproject', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${user.token}`
        },
        body: JSON.stringify(project)
      })

      if (!response.ok) {
        console.log('error')
      }
      const json = await response.json()

      if (response.ok) {
        console.log('Project created successfully')
        dispatch({type: 'CREATE_PROJECT', payload: json})
        console.log(project)
        setProjectID(json._id)
        setViewProjectSettings(false)
        setViewConfigureAI(true)
        setProgressBarWidth('24%')
      }
    }

    async function configureAI(e) {
      e.preventDefault()

      const patchProject = {modelUsed, prompt, end, max_tokens: maxTokens, temperature, top_p: topP, frequency_penalty: frequencyPenalty, presence_penalty: presencePenalty}
      console.log("Configuring AI...")
      const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/projects/' + projectID, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${user.token}`
        },
        body: JSON.stringify(patchProject)
      })

      if (!response.ok) {
        console.log('error')
      }

      if (response.ok) {
        console.log('AI configured successfully')
        setViewConfigureAI(false)
        setViewSelectFrontend(true)
        setProgressBarWidth('50%')
      }
    }

    async function selectFrontend(e) {
      e.preventDefault()
      console.log('Building UI...')
      const patchProject = {uiTemplate: layout, primaryColor, secondaryColor, bgColor, mainTextColor}
      const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/projects/' + projectID, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${user.token}`
        },
        body: JSON.stringify(patchProject)
      })

      if (!response.ok) {
        console.log('error')
      }

      if (response.ok) {
        console.log('UI built successfully')
        setViewSelectFrontend(false)
        setViewAdmin(true)
        setProgressBarWidth('70%')
    }
    }

    async function adminSettings(e) {
    e.preventDefault()
    console.log('Configuring admin settings...')
    console.log(selectedAuth.name)
    console.log(selectedMonetization.name)

    if (selectedAuth.name != 'No Authentication' || selectedMonetization.name != 'No Monetization') {
      alert('Please select a valid authentication and monetization method.')
      return
    }
    const patchProject = {authentication: selectedAuth.name, monetization: selectedMonetization.name}
    const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/projects/' + projectID, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${user.token}`
      },
      body: JSON.stringify(patchProject)
    })

    if (!response.ok) {
      console.log('error')
    }

    if (response.ok) {
      console.log('Admin settings configured successfully')
      setViewAdmin(false)
      setViewDeploy(true)
      setProgressBarWidth('100%')
    }
    }

    async function deploy(e) {
    e.preventDefault()
    console.log('Deploying project...')
    if (selectedDomain.name != 'Use Aspen domain') {
      alert('Please select a valid deployment method.')
      return
    }

    const patchProject = {deployed: true}
    const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/projects/' + projectID, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${user.token}`
      },
      body: JSON.stringify(patchProject)
    })

    if (!response.ok) {
      console.log('error')
    }

    if (response.ok) {
      console.log('Project deployed successfully')
      setSuccessfulDeploy(true)
      setViewDeploy(false)
    }
    }

    async function run(e){
    e.preventDefault()
    setLoading(true)
    const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/generalopenai', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      },
      body: JSON.stringify({prompt, command, end, modelUsed, maxTokens, temperature, topP, frequencyPenalty, presencePenalty}),
    })
    const json = await response.json()
    console.log(json)
    setLoading(false)
    setResult(json)
    }

    useEffect(() => {
      const fetchFineTunes = async () => {
        setLoading(true)
        const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/finetune/getfinetunerecords', {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        })
        const json = await response.json()
  
        if (response.ok) {
          setFineTunes(json)
          if (json.length === 0) {
            setZeroFineTunes(true)
          }
        }
        setLoading(false)
    }
    if (user) {
      fetchFineTunes()
    }
    }, [user])
  
    if (!user) {
      return (
        <div className="pt-20 w-full min-h-screen flex-2 text-center">
          <p className="mt-2 text-md font-medium text-gray-900">Not logged in</p>
          <h3 className="mt-2 text-sm font-medium text-gray-900">Please <a className='underline text-indigo-500' href='/login'>sign in to your account</a> or <a className='underline text-indigo-500' href='signup'>create a new account</a></h3>
        </div>
      )
    }
  
  return (
    <div className='w-full min-h-screen flex-2 pb-10'>
      <NavbarTwo />
        <header className="bg-white shadow">
          <div className="mx-20 max-w-7xl py-6 px-4 sm:px-6">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">Project Builder</h1>
          </div>
        </header>
        <main className='px-20'>
            <div className='py-10'>
                <h4 className="sr-only">Status</h4>
                <div className="mt-6" aria-hidden="true">
                    <div className="overflow-hidden rounded-full bg-gray-200">
                    <div className="h-2 rounded-full bg-indigo-600" style={{ width: progressBarWidth }} />
                    </div>
                    <div className="mt-6 hidden grid-cols-5 text-sm font-medium text-gray-600 sm:grid">
                    <div className="text-indigo-600">Project Settings</div>
                    <div className="">Configure AI</div>
                    <div className="text-center">Select frontend</div>
                    <div className="text-center">Admin</div>
                    <div className="text-right">Deploy</div>
                    </div>
                </div>
            </div>

        {
          viewProjectSettings ? (
          <form onSubmit={createProject} action="#" method="POST">
            <div className="space-y-6">
              <div>
                <h1 className="text-lg font-medium leading-6 text-gray-900">Project Settings</h1>
                <p className="mt-1 text-sm text-gray-500">
                  Let's get started by filling in the information below to create your new project.
                </p>
              </div>

              <div>
                <label htmlFor="project-name" className="block text-sm font-medium text-gray-700">
                  Project Name
                </label>
                <div className="mt-1">
                  <input
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    type="text"
                    name="project-name"
                    id="project-name"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                    defaultValue="Project Nero"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="project-name" className="block text-sm font-medium text-gray-700">
                  Project Description
                </label>
                <div className="mt-1">
                  <textarea
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                    rows={2}
                    type="text"
                    name="project-name"
                    id="project-name"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                  />
                </div>
              </div>

              <RadioGroup value={fineTune} onChange={setFineTune}>
                <RadioGroup.Label className="text-sm font-medium text-gray-900">AI Configuration</RadioGroup.Label>

                <div className="isolate mt-1 -space-y-px rounded-md bg-white shadow-sm">
                  {aiSettings.map((aiSettings, settingIdx) => (
                    <RadioGroup.Option
                      key={aiSettings.name}
                      value={aiSettings.boolean}
                      className={({ checked }) =>
                        classNames(
                          settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                          settingIdx === aiSettings.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                          checked ? 'bg-sky-50 border-sky-200 z-10' : 'border-gray-200',
                          aiSettings.active ? 'cursor-pointer' : 'cursor-not-allowed opacity-50',
                          'relative border p-4 flex focus:outline-none'
                        )
                      }
                    >
                      {({ active, checked }) => (
                        <>
                          <span
                            className={classNames(
                              checked ? 'bg-sky-600 border-transparent' : 'bg-white border-gray-300',
                              active ? 'ring-2 ring-offset-2 ring-sky-500' : '',
                              'mt-0.5 h-4 w-4 shrink-0 rounded-full border flex items-center justify-center'
                            )}
                            aria-hidden="true"
                          >
                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <span className="ml-3 flex flex-col">
                            <RadioGroup.Label
                              as="span"
                              className={classNames(
                                checked ? 'text-sky-900' : !aiSettings.active ? 'text-gray-500 cursor-not-allowed' : 'text-gray-900',
                                'block text-sm font-medium'
                              )}
                            >
                              {aiSettings.name}
                            </RadioGroup.Label>
                          </span>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>

              {
                !fineTune ? (
                  <div>
                  <RadioGroup value={selectedInput} onChange={setSelectedInput}>
                  <RadioGroup.Label className="text-sm font-medium text-gray-900">Input</RadioGroup.Label>
  
                  <div className="isolate mt-1 -space-y-px rounded-md bg-white shadow-sm">
                    {inputSettings.map((inputSettings, settingIdx) => (
                      <RadioGroup.Option
                        key={inputSettings.name}
                        value={inputSettings}
                        className={({ checked }) =>
                          classNames(
                            settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                            settingIdx === inputSettings.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                            checked ? 'bg-sky-50 border-sky-200 z-10' : 'border-gray-200',
                            inputSettings.active ? 'cursor-pointer' : 'cursor-not-allowed opacity-50',
                            'relative border p-4 flex focus:outline-none'
                          )
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <span
                              className={classNames(
                                checked ? 'bg-sky-600 border-transparent' : 'bg-white border-gray-300',
                                active ? 'ring-2 ring-offset-2 ring-sky-500' : '',
                                'mt-0.5 h-4 w-4 shrink-0 rounded-full border flex items-center justify-center'
                              )}
                              aria-hidden="true"
                            >
                              <span className="rounded-full bg-white w-1.5 h-1.5" />
                            </span>
                            <span className="ml-3 flex flex-col">
                              <RadioGroup.Label
                                as="span"
                                className={classNames(
                                  checked ? 'text-sky-900' : !inputSettings.active ? 'text-gray-500 cursor-not-allowed' : 'text-gray-900',
                                  'block text-sm font-medium'
                                )}
                              >
                                {inputSettings.name}
                              </RadioGroup.Label>
                              <RadioGroup.Description
                                as="span"
                                className={classNames(checked ? 'text-sky-700' : 'text-gray-500', 'block text-sm')}
                              >
                                {inputSettings.description}
                              </RadioGroup.Description>
                            </span>
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
  
                <RadioGroup value={selectedOutput} onChange={setSelectedOutput}>
                  <RadioGroup.Label className="text-sm font-medium text-gray-900">Output</RadioGroup.Label>
  
                  <div className="isolate mt-1 -space-y-px rounded-md bg-white shadow-sm">
                    {outputSettings.map((outputSettings, settingIdx) => (
                      <RadioGroup.Option
                        key={outputSettings.name}
                        value={outputSettings}
                        className={({ checked }) =>
                          classNames(
                            settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                            settingIdx === outputSettings.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                            checked ? 'bg-sky-50 border-sky-200 z-10' : 'border-gray-200',
                            outputSettings.active ? 'cursor-pointer' : 'cursor-not-allowed opacity-50',
                            'relative border p-4 flex cursor-pointer focus:outline-none'
                          )
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <span
                              className={classNames(
                                checked ? 'bg-sky-600 border-transparent' : 'bg-white border-gray-300',
                                active ? 'ring-2 ring-offset-2 ring-sky-500' : '',
                                'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center'
                              )}
                              aria-hidden="true"
                            >
                              <span className="rounded-full bg-white w-1.5 h-1.5" />
                            </span>
                            <span className="ml-3 flex flex-col">
                              <RadioGroup.Label
                                as="span"
                                className={classNames(
                                  checked ? 'text-sky-900' : 'text-gray-900',
                                  'block text-sm font-medium'
                                )}
                              >
                                {outputSettings.name}
                              </RadioGroup.Label>
                              <RadioGroup.Description
                                as="span"
                                className={classNames(checked ? 'text-sky-700' : 'text-gray-500', 'block text-sm')}
                              >
                                {outputSettings.description}
                              </RadioGroup.Description>
                            </span>
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
                </div>
                ) : null
                }

              <div className="flex justify-end">
                {/* <button
                  type="button"
                  className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                >
                  Exit
                </button> */}
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-sky-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                >
                  Next
                </button>
              </div>
            </div>
          </form>
          ) : null
        }
        {
          viewConfigureAI ? (
            <form onSubmit={configureAI} action="#" method="PATCH">
              {!fineTune ? ( 
                <div>
                  <div className='flex flex-row'>
                    <div className="basis-1/4 text-black rounded-2xl p-3 m-3 bg-white">
                      <h3 className="pb-3 text-lg font-medium leading-6 text-gray-900">Settings</h3>
                        <div className='py-3'>
                          <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                            Model
                          </label>
                          <select
                            onChange={(e) => setModel(e.target.value)}
                            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            defaultValue="text-davinci-003"
                          >
                            <option value={"text-davinci-003"}>text-davinci-003</option>
                            <option value={"text-curie-001"}>text-curie-001</option>
                            <option value={"text-babbage-001"}>text-babbage-001</option>
                            <option value={"text-ada-001"}>text-ada-001</option>
                          </select>
                        </div>
                        <Switch.Group as="div" className="pt-5 flex items-center justify-between">
                            <span className="flex flex-grow flex-col">
                              <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                                Advanced Settings
                              </Switch.Label>
                            </span>
                            <Switch
                              checked={enabled}
                              onChange={setEnabled}
                              className={classNames(
                                enabled ? 'bg-indigo-600' : 'bg-gray-200',
                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                              )}
                            >
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  enabled ? 'translate-x-5' : 'translate-x-0',
                                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                )}
                              />
                            </Switch>
                          </Switch.Group>
                          {enabled? (
                        <div>
                        <div className='py-3'>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Max Tokens
                            </label>
                            <div className="mt-1">
                            <input
                                type="number"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={maxTokens}
                                onChange={(e) => setMaxTokens(e.target.value)}
                            />
                            </div>
                        </div>
                        <div className='py-3'>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Temperature
                        </label>
                        <div className="mt-1">
                            <input
                            type="number"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="0.1"
                            value={temperature}
                            onChange={(e) => setTemperature(e.target.value)}
                            />
                        </div>
                        </div>
                        <div className='py-3'>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Top_P
                        </label>
                        <div className="mt-1">
                            <input
                            type="number"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            value={topP}
                            onChange={(e) => setTopP(e.target.value)}
                            />
                        </div>
                        </div>
                        <div className='py-3'>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Frequency Penalty
                        </label>
                        <div className="mt-1">
                            <input
                            type="number"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            value={frequencyPenalty}
                            onChange={(e) => setFrequencyPenalty(e.target.value)}
                            />
                        </div>
                        </div>
                        <div className='py-3'>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Presence Penalty
                        </label>
                        <div className="mt-1">
                            <input
                            type="number"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="0.1"
                            value={presencePenalty}
                            onChange={(e) => setPresencePenalty(e.target.value)}
                            />
                        </div>
                        </div>
                        </div>
                    ) : null}
    
                    </div>
                  <div className="basis-3/4 text-black bg-white rounded-2xl p-3 m-3">
                      <h3 className=" pb-3 text-lg font-medium leading-6 text-gray-900">Examples</h3>
                      <div className="bg-white appearance-none rounded-md relative block
                          w-full border border-gray-300
                          placeholder-gray-500 text-gray-900
                          focus:outline-none  focus:z-10 sm:text-sm">
                          <textarea 
                              name="description"
                              type="text"
                              value={prompt}
                              onChange={(e) => setPrompt(e.target.value)}
                              rows={25}
                              autoComplete="on"
                              className="appearance-none rounded-md relative block
                              w-full px-3 py-2 border border-gray-300
                              placeholder-gray-500 text-gray-900
                              focus:outline-none  focus:z-10 sm:text-sm"
                              placeholder='Enter text here'
                          />
                      </div>
                      <h3 className="pt-3 pb-3 text-lg font-medium leading-6 text-gray-900">Command</h3>
                      <div className="bg-white appearance-none rounded-md relative block
                          w-full border border-gray-300
                          placeholder-gray-500 text-gray-900
                          focus:outline-none  focus:z-10 sm:text-sm">
                          <textarea 
                              name="description"
                              type="text"
                              value={command}
                              onChange={(e) => setCommand(e.target.value)}
                              rows={3}
                              autoComplete="on"
                              className="appearance-none rounded-md relative block
                              w-full px-3 py-2 border border-gray-300
                              placeholder-gray-500 text-gray-900
                              focus:outline-none  focus:z-10 sm:text-sm"
                              placeholder='Enter text here'
                          />
                      </div>
                      <h3 className="pt-3 pb-3 text-lg font-medium leading-6 text-gray-900">End</h3>
                      <div className="bg-white appearance-none rounded-md relative block
                          w-full border border-gray-300
                          placeholder-gray-500 text-gray-900
                          focus:outline-none  focus:z-10 sm:text-sm">
                          <textarea 
                              name="description"
                              type="text"
                              value={end}
                              onChange={(e) => setEnd(e.target.value)}
                              rows={1}
                              autoComplete="on"
                              className="appearance-none rounded-md relative block
                              w-full px-3 py-2 border border-gray-300
                              placeholder-gray-500 text-gray-900
                              focus:outline-none  focus:z-10 sm:text-sm"
                              placeholder='Enter text here'
                          />
                      </div>
                      <div className="pt-3 flex">
                          <div className="w-1/2">
                              <button onClick={run} className="w-20 float-left py-2 px-4 mr-3 border border-transparent text-sm font-medium
                              rounded-md text-white bg-purple-700 hover:bg-sky-500
                              focus:outline-none focus:ring-2 focus:ring-offset-2
                              focus:ring-indigo-500">
                                  Run
                              </button>
                          </div>
                      </div>
                  </div>
                  <div className="basis-1/4 text-black rounded-2xl p-3 m-3 bg-white">
                    <div className='py-3'>
                    <h3 className=" pb-3 text-lg font-medium leading-6 text-gray-900">Output</h3>
    
                        {loading ? (
                        <p>Loading...</p>
                        ) : result ? (
                        <p>{result}</p>
                        ) : (
                        <p className='text-gray-400'>Result prints here</p>
                        )}
                    </div>
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <button
                      onClick={() => {
                        setViewConfigureAI(false)
                        setViewProjectSettings(true)
                        setProgressBarWidth('5.5%')
                      }}
                      type="button"
                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                    >
                      Back
                    </button>
                    <button
                      type="submit"
                      className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-sky-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                    >
                      Next
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                  Enter the OpenAI Name of your model
                  </label>
                  <input
                    onChange={(e) => setModel(e.value)}
                    value={modelUsed}
                    type="text"
                    className="my-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                  />

                  <div className="flex justify-end">
                    <button
                      onClick={() => {
                        setViewConfigureAI(false)
                        setViewProjectSettings(true)
                        setProgressBarWidth('5.5%')
                      }}
                      type="button"
                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                    >
                      Back
                    </button>
                    <button
                      type="submit"
                      className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-sky-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}

            </form>
          ) : null
        }
        {
          viewSelectFrontend ? (
            <div>
              {/* <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                {files.map((file) => (
                  <li key={file.source} className="relative">
                    <button onClick={() => setSelectedFrontend(file.layout)}>
                    <div className={selectedFrontend === file.layout ?
                      "group block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden bg-white border-4 border-indigo-500 shadow-lg cursor-pointer focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-sky-500" :
                      "group block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden bg-white border-2 border-gray-300 shadow-lg cursor-pointer focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-sky-500"}>
                      <img src={file.source} alt="" className="pointer-events-none object-cover group-hover:opacity-75" />
                      <button type="button" className="absolute inset-0 focus:outline-none">
                        <span className="sr-only">View details for {file.title}</span>
                      </button>
                    </div>
                    <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{file.title}</p>
                    </button>
                  </li>
                ))}
              </ul> */}
                                  <div className='flex flex-row'>
                    <div className="basis-1/4 text-black rounded-2xl p-3 m-3 bg-white">
                        <div className='py-3'>
                        <h3 className=" pb-3 text-lg font-medium leading-6 text-gray-900">Settings</h3>
                            <label className="block text-sm font-medium text-gray-700">
                            Layout Template
                            </label>
                            <select
                            onChange={(e) => setLayout(e.target.value)}
                            value={layout}
                            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            >
                            <option value={'LayoutOne'}>Layout #1</option>
                            <option value={'LayoutTwo'}>Layout #2</option>
                            </select>
                        </div>
                            <div>
                            <div className='py-3'>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Primary Color
                                </label>
                                <p className="block text-sm font-medium text-gray-700">{primaryColor}</p>
                                <div className="mt-1">
                                <input
                                    type="color"
                                    className="block rounded-md"
                                    value={primaryColor}
                                    onChange={(e) => setPrimaryColor(e.target.value)}

                                />
                                </div>
                            </div>
                            <div className='py-3'>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Secondary Color
                                </label>
                                <p className="block text-sm font-medium text-gray-700">{secondaryColor}</p>
                                <div className="mt-1">
                                    <input
                                    type="color"
                                    className="block rounded-md"
                                    value={secondaryColor}
                                    onChange={(e) => setSecondaryColor(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='py-3'>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Background Color
                                </label>
                                <p className="block text-sm font-medium text-gray-700">{bgColor}</p>
                                <div className="mt-1">
                                <input
                                    type="color"
                                    className="block rounded-md"
                                    value={bgColor}
                                    onChange={(e) => setBgColor(e.target.value)}

                                />
                                </div>
                            </div>
                            <div className='py-3'>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Main Text Color
                                </label>
                                <p className="block text-sm font-medium text-gray-700">{mainTextColor}</p>
                                <div className="mt-1">
                                <input
                                    type="color"
                                    className="block rounded-md"
                                    value={mainTextColor}
                                    onChange={(e) => setMainTextColor(e.target.value)}
                                />
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="basis-3/4 text-black bg-white rounded-2xl p-3 m-3">
                            {layout === 'LayoutOne' ? (
                                <div
                                style={{backgroundColor: bgColor}}
                                className="flex rounded-md min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
                                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                                        <h2 style={{color: mainTextColor}} className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Title</h2>
                                        <p style={{color: mainTextColor}} className="mt-2 text-center text-sm text-gray-600">Description</p>
                                    </div>
                                    
                                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                                        <div style={{backgroundColor: bgColor}} className=" py-8 px-4 rounded-md shadow sm:rounded-lg sm:px-10">
                                        <form className="space-y-6" >
                                            <div style={{borderBlockColor: secondaryColor}}>
                                            <div className="mt-1">
                                                <textarea
                                                rows={3}
                                                style={{backgroundColor: bgColor, color: mainTextColor}}
                                                onFocus={e => {
                                                    e.target.style.borderColor = secondaryColor;
                                                    }}
                                                onBlur={e => {
                                                e.target.style.borderColor = bgColor;
                                                }}

                                                className="block rounded-md w-full appearance-none  border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
                                                placeholder="Prompt for user input"
                                                />
                                            </div>
                                            </div>
                            
                                            <div>
                                            <button
                                                type="submit"
                                                style={{backgroundColor: primaryColor}}
                                                className="flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                Go
                                            </button>
                                            </div>
                                        </form>
                            
                                        <div style={{color: mainTextColor}} className="mt-6">
                                            <div className="relative">
                                            <div className="relative flex justify-center text-sm">
                                                <p className="px-2 text-gray-400">// result prints here</p>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <p className='justify-center relative flex py-5'><a style={{color: mainTextColor}} className='underline' href="/" target="_blank">Create your own AI-powered app with Aspen</a></p>
                                </div>
                            ) : layout === 'LayoutTwo' ? (
                                <div
                                style={{backgroundColor: bgColor}}
                                className="flex min-h-full rounded-md flex-col justify-center py-12 sm:px-6 lg:px-8">
                                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                                      <h2 style={{color: mainTextColor}} className="mt-6 text-center text-3xl font-bold tracking-tight">Title</h2>
                                      <p style={{color: mainTextColor}} className="mt-2 text-center text-sm ">Description</p>
                                    </div>
                                <div className="min-w-0 flex-1 sm:mx-auto sm:w-full sm:max-w-md mt-20">
                                  <form >
                                    <div 
                                    style={{borderBlockColor: secondaryColor}}
                                    className="border-b border-gray-200">
                                      <textarea
                                        rows={3}
                                        style={{backgroundColor: bgColor, color: mainTextColor}}
                                        onFocus={e => {
                                            e.target.style.borderColor = secondaryColor;
                                            }}
                                        onBlur={e => {
                                        e.target.style.borderColor = bgColor;
                                        }}
                                        className="block w-full rounded-md resize-noneborder-0 border-transparent pb-2 focus:ring-0 sm:text-sm"
                                        placeholder="Prompt for user input"
                                        defaultValue={''}
                                      />
                                    </div>
                                    <div className="flex justify-between p-2">
                                      <div className="flex-shrink-0">
                                        <button
                                          type="submit"
                                          style={{backgroundColor: primaryColor}}
                                          className="inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                          Go
                                        </button>
                                      </div>
                                    </div>
                                    <div style={{color: mainTextColor}} className="pt-10 text-sm">
                                    </div>
                                  </form>
                                </div>
                              </div>
                            ) : null}
                        </div>
                    </div>
              <div className="flex justify-end">
                <button
                  onClick={() => {
                    setViewSelectFrontend(false)
                    setViewConfigureAI(true)
                    setProgressBarWidth('24%')
                  }}
                  type="button"
                  className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                >
                  Back
                </button>
                <button
                  onClick={selectFrontend}
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-sky-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                >
                  Next
                </button>
              </div>
            </div>
          ) : null
        }
        {
          viewAdmin ? (
            <form onSubmit={adminSettings}>
            <div className="space-y-6">
              <div>
                <h1 className="text-lg font-medium leading-6 text-gray-900">Admin Settings</h1>
                <p className="mt-1 text-sm text-gray-500">
                  Let's get started by filling in the information below to create your new project.
                </p>
              </div>


              <RadioGroup value={selectedAuth} onChange={setSelectedAuth}>
                <RadioGroup.Label className="text-sm font-medium text-gray-900">Authentication</RadioGroup.Label>

                <div className="isolate mt-1 -space-y-px rounded-md bg-white shadow-sm">
                  {authSettings.map((authSettings, settingIdx) => (
                    <RadioGroup.Option
                      key={authSettings.name}
                      value={authSettings}
                      className={({ checked }) =>
                        classNames(
                          settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                          settingIdx === authSettings.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                          checked ? 'bg-sky-50 border-sky-200 z-10' : 'border-gray-200',
                          authSettings.active ? 'cursor-pointer' : 'cursor-not-allowed opacity-50',
                          'relative border p-4 flex focus:outline-none'
                        )
                      }
                    >
                      {({ active, checked }) => (
                        <>
                          <span
                            className={classNames(
                              checked ? 'bg-sky-600 border-transparent' : 'bg-white border-gray-300',
                              active ? 'ring-2 ring-offset-2 ring-sky-500' : '',
                              'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center'
                            )}
                            aria-hidden="true"
                          >
                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <span className="ml-3 flex flex-col">
                            <RadioGroup.Label
                              as="span"
                              className={classNames(
                                checked ? 'text-sky-900' : 'text-gray-900',
                                'block text-sm font-medium'
                              )}
                            >
                              {authSettings.name}
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="span"
                              className={classNames(checked ? 'text-sky-700' : 'text-gray-500', 'block text-sm')}
                            >
                              {authSettings.description}
                            </RadioGroup.Description>
                          </span>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>

              <RadioGroup value={selectedMonetization} onChange={setSelectedMonetization}>
                <RadioGroup.Label className="text-sm font-medium text-gray-900">Monetization</RadioGroup.Label>

                <div className="isolate mt-1 -space-y-px rounded-md bg-white shadow-sm">
                  {monetizationSettings.map((monetizationSettings, settingIdx) => (
                    <RadioGroup.Option
                      key={monetizationSettings.name}
                      value={monetizationSettings}
                      className={({ checked }) =>
                        classNames(
                          settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                          settingIdx === monetizationSettings.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                          checked ? 'bg-sky-50 border-sky-200 z-10' : 'border-gray-200',
                          monetizationSettings.active ? 'cursor-pointer' : 'cursor-not-allowed opacity-50',
                          'relative border p-4 flex focus:outline-none'
                        )
                      }
                    >
                      {({ active, checked }) => (
                        <>
                          <span
                            className={classNames(
                              checked ? 'bg-sky-600 border-transparent' : 'bg-white border-gray-300',
                              active ? 'ring-2 ring-offset-2 ring-sky-500' : '',
                              'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center'
                            )}
                            aria-hidden="true"
                          >
                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <span className="ml-3 flex flex-col">
                            <RadioGroup.Label
                              as="span"
                              className={classNames(
                                checked ? 'text-sky-900' : 'text-gray-900',
                                'block text-sm font-medium'
                              )}
                            >
                              {monetizationSettings.name}
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="span"
                              className={classNames(checked ? 'text-sky-700' : 'text-gray-500', 'block text-sm')}
                            >
                              {monetizationSettings.description}
                            </RadioGroup.Description>
                          </span>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
              
              {selectedMonetization.name != "No Monetization" ? (
                <div>
                  <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                      Price (USD)
                  </label>
                  <div className="relative mt-1 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-500 sm:text-sm">$</span>
                      </div>
                      <input
                      type="text"
                      name="price"
                      id="price"
                      className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="0.00"
                      aria-describedby="price-currency"
                      />
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <span className="text-gray-500 sm:text-sm" id="price-currency">
                          Monthly
                      </span>
                      </div>
                  </div>
                </div>
              ) : null}


              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => {
                    setViewSelectFrontend(true)
                    setViewAdmin(false)
                    setProgressBarWidth('50%')
                  }}
                  className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-sky-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                >
                  Next
                </button>
              </div>
            </div>
            </form>
          ) : null
        }
        {
          viewDeploy ? (
            <form onSubmit={deploy}>
            <div className="space-y-6">
              <div>
                <h1 className="text-lg font-medium leading-6 text-gray-900">Deploy Settings</h1>
                <p className="mt-1 text-sm text-gray-500">
                  You're almost ready to deploy your project! Please review the settings below and click "Deploy" to get started.
                </p>
              </div>


              <RadioGroup value={selectedDomain} onChange={setSelectedDomain}>
                <RadioGroup.Label className="text-sm font-medium text-gray-900">Domain</RadioGroup.Label>

                <div className="isolate mt-1 -space-y-px rounded-md bg-white shadow-sm">
                  {domainSettings.map((domainSettings, settingIdx) => (
                    <RadioGroup.Option
                      key={domainSettings.name}
                      value={domainSettings}
                      className={({ checked }) =>
                        classNames(
                          settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                          settingIdx === domainSettings.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                          checked ? 'bg-sky-50 border-sky-200 z-10' : 'border-gray-200',
                          'relative border p-4 flex cursor-pointer focus:outline-none',
                          domainSettings.active ? 'cursor-pointer' : 'cursor-not-allowed opacity-50',
                          'relative border p-4 flex focus:outline-none'
                        )
                      }
                    >
                      {({ active, checked }) => (
                        <>
                          <span
                            className={classNames(
                              checked ? 'bg-sky-600 border-transparent' : 'bg-white border-gray-300',
                              active ? 'ring-2 ring-offset-2 ring-sky-500' : '',
                              'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center'
                            )}
                            aria-hidden="true"
                          >
                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <span className="ml-3 flex flex-col">
                            <RadioGroup.Label
                              as="span"
                              className={classNames(
                                checked ? 'text-sky-900' : 'text-gray-900',
                                'block text-sm font-medium'
                              )}
                            >
                              {domainSettings.name}
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="span"
                              className={classNames(checked ? 'text-sky-700' : 'text-gray-500', 'block text-sm')}
                            >
                              {domainSettings.description}
                            </RadioGroup.Description>
                          </span>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
              

              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => {
                    setViewAdmin(true)
                    setViewDeploy(false)
                    setProgressBarWidth('50%')
                  }}
                  className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-sky-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                >
                  Deploy
                </button>
              </div>
            </div>
            </form>
          ) : successfulDeploy ? (
            <div>
              <p>Project deployed successfully!</p>
              <a className='underline text-indigo-500' href="/dashboard">Go to dashboard</a>
            </div>
          ) : null
        }
        </main>
    </div>
  )
}

export default ProjectBuilder