import React, { useState, useEffect } from 'react';
import NavbarTwo from '../components/NavbarTwo';
import { useAuthContext } from '../hooks/useAuthContext';
import { BoltIcon, WrenchScrewdriverIcon } from '@heroicons/react/20/solid'
import { ArrowUpRightIcon, CpuChipIcon, Cog8ToothIcon, BookOpenIcon, GlobeAltIcon } from '@heroicons/react/24/outline'



const Dashboard = () => {
  const { user } = useAuthContext();
  const [zeroProjects, setZeroProjects] = useState(false);
  const [projects, setProjects] = useState([])
  const [fineTunes, setFineTunes] = useState([])
  const [loading, setLoading] = useState(false)


const stats = [
    { name: 'Active Projects', stat: projects.length },
    { name: 'Fine tunes', stat: fineTunes.length },
]

const actions = [
    {
      title: 'Create a new project',
      href: '/projectbuilder',
      icon: WrenchScrewdriverIcon,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
      description: 'Create a new project using our intuitive studio.',
    },
    {
      title: 'Experiment in the sandbox',
      href: '/sandbox',
      icon: BoltIcon,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
      description: 'Try out new ideas in a safe environment before shipping them.',
    },
    {
      title: 'Create a fine tune',
      href: '/modelfinetuning',
      icon: CpuChipIcon,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
      description: 'Fine tune a model using your own data.',
    },
    {
      title: 'View account settings',
      href: '/settings',
      icon: Cog8ToothIcon,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
      description: 'Configure your account settings and preferences.',
    },
    {
      title: 'Docs',
      href: '//docs.getaspenai.com/',
      icon: BookOpenIcon,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
      description: 'Get started with our documentation.',
    },
    {
      title: 'Join the community',
      href: '//discord.gg/YxNVvks8v4',
      icon: GlobeAltIcon,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
      description: 'Meet other like-minded builders.',
    },
]

useEffect(() => {
  setLoading(true)
  const fetchProjects = async () => {
    const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/projects/', {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    })
    const json = await response.json()

    if (response.ok) {
      setProjects(json)
      if (json.length === 0) {
        setZeroProjects(true)
      }
    }
    setLoading(false)
}

const fetchModels = async () => {
  const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/finetune/getfinetunerecords', {
    headers: {
      'Authorization': `Bearer ${user.token}`
    }
  })
  const json = await response.json()

  if (response.ok) {
    setFineTunes(json)
  }
  setLoading(false)
}
if (user) {
  fetchProjects()
  fetchModels()
}
}, [user])

const recentProjects = projects.slice(0, 4)

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

if (!user) {
  return (
    <div className="pt-20 w-full min-h-screen flex-2 text-center">
      <p className="mt-2 text-md font-medium text-gray-900">Not logged in</p>
      <h3 className="mt-2 text-sm font-medium text-gray-900">Please <a className='underline text-indigo-500' href='/login'>sign in to your account</a> or <a className='underline text-indigo-500' href='signup'>create a new account</a></h3>
    </div>
  )
}
  return (
      <div className='w-full min-h-screen flex-2'>
        <NavbarTwo />
        <header className="bg-white shadow">
          <div className="mx-20 max-w-7xl pt-6 pb-3  px-4 sm:px-6">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">Dashboard</h1>
          </div>
        </header>

        <main className='mx-20 pb-3 px-4 sm:px-6'>
            <div className="py-10">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Recent Projects</h3>
                {loading? (
                  <p>Loading...</p>
                  ) : zeroProjects ? (
                  <div className="rounded-md bg-purple-50 px-4 py-8 mt-4">
                  <div className="flex">
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                      <p className=" text-indigo-700">No recent projects</p>
                      <p className="mt-3 md:mt-0 md:ml-6">
                        <a href="/projectbuilder" className="whitespace-nowrap font-medium text-purple-700 hover:text-purple-600">
                          Go to Project Builder
                          <span aria-hidden="true"> &rarr;</span>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                ) : (
                  <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
                  {recentProjects.map((recentProjects) => (
                  <li key={recentProjects._id} className="col-span-1 flex rounded-md shadow-sm">
                      <div
                      className='bg-purple-700 flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md'
                      >
                      {recentProjects.title[0]}
                      </div>
                      <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-t border-r border-b border-gray-200 bg-white">
                      <div className="flex-1 truncate px-4 py-6 text-sm">
                          <a href={`/projects/${recentProjects._id}`} target="_blank" className="font-medium text-gray-900 hover:text-gray-600">
                          {recentProjects.title}
                          </a>
                      </div>
                      <div className="flex-shrink-0 pr-2">
                          <a
                          href={`/projects/${recentProjects._id}`}
                          target="_blank"
                          className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-white bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                          <span className="sr-only">Open options</span>
                          <ArrowUpRightIcon className="h-5 w-5" aria-hidden="true" />
                          </a>
                      </div>
                      </div>
                  </li>
                  ))}
                  </ul>
                )}

            </div>
            <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">Overview</h3>
                <dl className="py-2 grid grid-cols-1 gap-5 sm:grid-cols-2">
                    {stats.map((item) => (
                    <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                        <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
                    </div>
                    ))}
                </dl>
            </div>

            <div className=''>
              <h3 className="pt-6 pb-3 text-lg font-medium leading-6 text-gray-900">Actions</h3>
              <div className="divide-y divide-gray-200 overflow-hidden rounded-lg shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
                {actions.map((action, actionIdx) => (
                    <div
                    key={action.title}
                    className={classNames(
                        actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                        actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                        actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                        actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                        'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
                    )}
                    >
                    <div>
                        <span
                        className={classNames(
                            action.iconBackground,
                            action.iconForeground,
                            'rounded-lg inline-flex p-3 ring-4 ring-white'
                        )}
                        >
                        <action.icon className="h-6 w-6" aria-hidden="true" />
                        </span>
                    </div>
                    <div className="mt-2">
                        <h3 className="text-lg font-medium">
                        <a href={action.href} className="focus:outline-none">
                            {/* Extend touch target to entire panel */}
                            <span className="absolute inset-0" aria-hidden="true" />
                            {action.title}
                        </a>
                        </h3>
                        <p className="mt-2 text-sm text-gray-500">
                            {action.description}
                        </p>
                    </div>
                    <span
                        className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                        aria-hidden="true"
                    >
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                        </svg>
                    </span>
                    </div>
                ))}
                </div>
            </div>
        </main>
      </div>
  )
}

export default Dashboard;