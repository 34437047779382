import React, { useState } from 'react';
import { CogIcon, UserCircleIcon } from '@heroicons/react/24/outline'
import ProfileSettings from '../components/ProfileSettings';
import AccountSettings from '../components/AccountSettings';
import NavbarTwo from '../components/NavbarTwo';
import { useAuthContext } from '../hooks/useAuthContext';


const Settings = () => {
    const [render, setRender] = useState(<ProfileSettings />)
    const [current, setCurrent] = useState("Profile")
    const { user } = useAuthContext();


    const subNavigation = [
        { name: 'Profile', render: <ProfileSettings />, icon: UserCircleIcon, current: false },
        { name: 'Billing', render: <AccountSettings />, icon: CogIcon, current: false },
    ]

    function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
    }

    if (!user) {
      return (
        <div className="pt-20 w-full min-h-screen flex-2 text-center">
          <p className="mt-2 text-md font-medium text-gray-900">Not logged in</p>
          <h3 className="mt-2 text-sm font-medium text-gray-900">Please <a className='underline text-indigo-500' href='/login'>sign in to your account</a> or <a className='underline text-indigo-500' href='signup'>create a new account</a></h3>
        </div>
      )
    }

  return (
      <div className='w-full min-h-screen flex-2'>
        <NavbarTwo />
        <header className="bg-white shadow">
          <div className="mx-20 max-w-7xl py-6 px-4 sm:px-6">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">Settings</h1>
          </div>
        </header>

        <main className='mx-20'>
        <div className="mt-10  pb-6 sm:px-6 lg:pb-16">
          <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
              <aside className="py-6 lg:col-span-3">
                <nav className="space-y-1">
                  {subNavigation.map((item) => (
                    <button
                      key={item.name}
                      onClick={() => {
                        setRender(item.render)
                        setCurrent(item.name)
                      }}
                      className={classNames(
                        item.name === current
                          ? 'bg-purple-50 border-purple-700 w-full text-purple-700 hover:bg-purple-50 hover:text-purple-700'
                          : 'border-transparent w-full text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                        'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
                      )}
                      // aria-current={item.current ? 'page' : undefined}
                    >
                      <item.icon
                        className={classNames(
                          item.current
                            ? 'text-purple-700 group-hover:text-purple-50'
                            : 'text-gray-400 group-hover:text-gray-500',
                          'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      <span className="truncate">{item.name}</span>
                    </button>
                  ))}
                </nav>
              </aside>
            {render}
            </div>
          </div>
        </div>
        </main>
      </div>
  )
}

export default Settings;