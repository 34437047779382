
export default function AccountSettings() {
    return (
      <form className="divide-y divide-gray-200 lg:col-span-9" action="#" method="POST">
        <div className="py-6 px-4 sm:p-6 lg:pb-8">
          <div>
              <h2 className="text-lg font-medium leading-6 text-gray-900">Plan & Billing</h2>
          </div>

          <div className="mt-6 grid grid-cols-12 gap-6">
            <div className="col-span-12 sm:col-span-6">
                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                            Plan
                        </label>
                          Trial
            </div>
            <div className="col-span-12 sm:col-span-6">
              <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                  View billing history and manage your plan
              </label>
              <a
              // href='//payments.getaspenai.com/p/login/7sI9Dm79kcyR9BCfYY'
              target="_blank">
                  <button
                      type="button"
                      // onclick create alert that says coming soon
                      onClick={() => alert('Coming soon!')}
                      className="mt-2 inline-flex justify-center rounded-md border border-transparent bg-indigo-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                      Manage plan
                  </button>
              </a>
            </div>
          </div>
        </div>
      </form>
    )
}