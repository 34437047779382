import React, { useState } from 'react';
import { useParams } from 'react-router-dom'


const ChangePassword = () => {
    const [password, setPassword] = useState('');
    const [passwordTwo, setPasswordTwo] = useState('');
    const [error, setError] = useState('');
    const [successful, setSuccessful] = useState(false);
    const [loading, setLoading] = useState(false);

    const params = useParams()


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== passwordTwo) {
            setError('Passwords do not match')
            return
        }
        setLoading(true)

        const userID = params.userID
        const token = params.token
        const newPassword = password

        const response = await fetch(`https://swe-jarvis-one-a.herokuapp.com/api/user/resetpassword/${userID}/${token}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ newPassword })
        })

        const json = await response.json()

        if (response.ok) {
            setLoading(false)
            setSuccessful(true)
        }
        if (!response.ok) {
            setLoading(false)
            setError(json)
        }
    }
        

    return (
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Reset your password</h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    New Password
                  </label>
                  <div className="mt-1">
                    <input
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Verify password
                  </label>
                  <div className="mt-1">
                    <input
                      onChange={(e) => setPasswordTwo(e.target.value)}
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Reset password
                </button>
              </div>
            </form>
            {error && <p className="block text-sm font-medium text-gray-700 pt-2">{error}</p>}
            {successful && <p className="block text-sm font-medium text-gray-700 pt-2">Password has been reset.</p>}
            {loading && <p className="block text-sm font-medium text-gray-700 pt-2">Loading...</p>}

          </div>
        </div>
      </div>

    )
}

export default ChangePassword;