import { useAuthContext } from '../hooks/useAuthContext';

export default function ProfileSettings() {
    const { user } = useAuthContext()

    return (
        <form className="divide-y divide-gray-200 lg:col-span-9" action="#" method="POST">
            <div className="py-6 px-4 sm:p-6 lg:pb-8">
                <div>
                    <h2 className="text-lg font-medium leading-6 text-gray-900">Profile Settings</h2>
                </div>

                <div className="mt-6 grid grid-cols-12 gap-6">
                    <div className="col-span-12 sm:col-span-6">
                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                            Email
                        </label>
                        {user && <p className="pt-2 block text-sm font-medium text-gray-700">{user.email}</p>}
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                        <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                            Password
                        </label>
                        <a href='/requestpasswordreset'>
                            <button
                                type="button"
                                className="mt-2 inline-flex justify-center rounded-md border border-transparent bg-indigo-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                Reset password
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </form>
        
    )
}