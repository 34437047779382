import React, { useState, useEffect } from 'react';
import NavbarTwo from '../../components/NavbarTwo';
import { useAuthContext } from '../../hooks/useAuthContext';

const people = [
    { name: 'Lindsay Walton', title: 'Front-end Developer', plan: 'Free' },
    // More people...
  ]
const Users = () => {
    const { user } = useAuthContext();
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        const fetchUsers = async () => {
          const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/user/allusers', {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
          })
          const json = await response.json()
      
          if (response.ok) {
            setUsers(json)
          }
          setLoading(false)
        }
        if (user) {
            fetchUsers()
        }
    }, [user])



    if (!user) {
        return (
          <div className="pt-20 w-full min-h-screen flex-2 text-center">
            <p className="mt-2 text-md font-medium text-gray-900">Not logged in</p>
            <h3 className="mt-2 text-sm font-medium text-gray-900">Please <a className='underline text-indigo-500' href='/login'>sign in to your account</a> or <a className='underline text-indigo-500' href='signup'>create a new account</a></h3>
          </div>
        )
      }

    if (user.email !== 'zaais@test.com' && user.email !== 'zaais@bountyboard.ca' && user.email !== 'francois@bountyboard.ca') {
        return (
            <div className="pt-20 w-full min-h-screen flex-2 text-center">
              <p className="mt-2 text-md font-medium text-gray-900">Unauthorized</p>
              <h3 className="mt-2 text-sm font-medium text-gray-900"><a className='underline text-indigo-500' href='/dashboard'>Return to dashboard</a></h3>
            </div>
          )
    }

  return (
    <div className='w-full min-h-screen flex-2'>
        <NavbarTwo />
        <header className="bg-white shadow">
          <div className="mx-20 max-w-7xl py-6 px-4 sm:px-6">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">Users</h1>
            {users && <p className="mt-1 text-sm text-gray-500">Total users: {users.length}</p>}
          </div>
        </header>
        <main>
        <div className="px-20 overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Email
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      User ID
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Plan
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {users.map((user) => (
                    <tr key={user.email}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {user.email}
                      </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user._id}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">Free</td>
                      <td className="whitespace-nowrap px-3 p-4 text-sm text-gray-500">
                        <a href={`/internal/user/${user._id}`} className="text-indigo-600 hover:text-indigo-900">
                            <button
                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                View
                            </button>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        </main>
    </div>
  )
}

export default Users