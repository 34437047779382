import React, {useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import LayoutOne from '../components/projectUIOptions/LayoutOne'
import LayoutTwo from '../components/projectUIOptions/LayoutTwo'

const DeployedProject = () => {
  const [project, setProject] = useState({})

  const params = useParams()
  const id = params.id

  useEffect(() => {
    const fetchProject = async () => {
      const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/external/' + id, {
        method: 'GET',
      })

      const json = await response.json()
      
      if (response.ok) {
        setProject(json)
      }
      if (!response.ok) {
        console.log('error')
      }
    }
    fetchProject()
  }, [id])

  if (project.uiTemplate === 'LayoutOne') {
    return (
      <LayoutOne project={project} key={project._id}/>
    )
  } else if (project.uiTemplate === 'LayoutTwo') {
    return (
      <LayoutTwo project={project} key={project._id}/>
    )
  } else {
    return ( 
      <LayoutOne project={project} key={project._id}/>

  )}
}

export default DeployedProject