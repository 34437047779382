import React, { useState } from 'react';
import { ArrowUpRightIcon } from '@heroicons/react/20/solid'
import { ArchiveBoxXMarkIcon, Cog6ToothIcon } from '@heroicons/react/24/outline'
import { useAuthContext } from '../hooks/useAuthContext';


const MyChatProjects = ({ ChatProject }) => {
  const { user } = useAuthContext();


  const handleDelete = async () => {
    if (!user) {
      return
    }

    if (!window.confirm('Are you sure you want to delete this project?')) {
      return
    }

    // const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/projects/' + project._id, {
    //   method: 'DELETE',
    //   headers: {
    //     'Authorization': `Bearer ${user.token}`
    //   }
    // })
    // const json = await response.json()

    // if (response.ok) {
    //   console.log('Project deleted')
    //   window.location.reload()
    // }
    // if (!response.ok) {
    //   console.log({'error': json})
    // }
    console.log('no delete route yet')
  }

  return (
    <li  className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
    <div className="flex w-full items-center justify-between space-x-6 p-6">
        <div className="flex-1 truncate">
        <div className="flex items-center space-x-3">
            <h3 className="truncate text-sm font-medium text-gray-900">{ChatProject.title}</h3>
        </div>
        </div>
    </div>
    <div>
        <div className="-mt-px flex divide-x divide-gray-200">
            <div className="-ml-px flex w-0 flex-1">
                <button
                onClick={handleDelete}
                className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
                >
                <span className="mr-3">Delete</span>
                <ArchiveBoxXMarkIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </button>
            </div>
            {/* <div className="-ml-px flex w-0 flex-1">
                <a
                href={`/projectsettings/${project._id}`}
                className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
                >
                <span className="mr-3">Settings</span>
                <Cog6ToothIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </a>
            </div> */}
            <div className="-ml-px flex w-0 flex-1">
                <a
                href={`/chatbot/${ChatProject._id}`}
                target="_blank"
                className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
                >
                <span className="mr-3">View</span>
                <ArrowUpRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </a>
            </div>
        </div>
    </div>
    </li>
  )
}

export default MyChatProjects;