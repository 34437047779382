import React, { useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'

const chatData = []

let openAIPrompt = "The following is a conversation with Willow, an AI assistant. The assistant is helpful, creative, clever, and very friendly.\n\nHuman: Hello, who are you?\nAI: I am an AI created by OpenAI. How can I help you today?\n\n"
// need to make sure prompt works with sandbox


const LayoutThree = ({ project }) => {
    const [loading, setLoading] = useState(false)
    const [command, setCommand] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    async function handleSubmit(e) {
        e.preventDefault()
        setLoading(true)

        let prompt = openAIPrompt + 'Human: ' + command + '\nAI:'
        
        const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/generalopenai', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ modelUsed: project.modelUsed, command, prompt: project.prompt, end: project.end, maxTokens: project.max_tokens, temperature: project.temperature, topP: project.top_p, frequencyPenalty: project.frequency_penalty, presencePenalty: project.presence_penalty}),

        })
        const json = await response.json()

        console.log(response.body)

        if (response.ok) {
            prompt = prompt + " " + json + "\n\n"
            chatData.push({ Human: command, AI: json })
            openAIPrompt = prompt
            setLoading(false)
            setCommand('')
        }
        if (!response.ok) {
            setLoading(false)
            setErrorMessage("Error")
            console.log(json)
        }

      }


    if (isMobile) {
        return (
            <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">{project.title}</h2>
                    <p className='text-center pt-3'>Powered by <a className='underline text-indigo-500' href='https://www.getaspenai.com/' target="_blank">Aspen</a></p>
                </div>

            <div className="sm:mx-auto sm:w-full mt-8 w-full lg:px-64 sm:px-2">
                {chatData.map((chat) => (
                    <div key={chat.Human}>
                        <div className='bg-gray-200 p-2 rounded-md'>
                            <p>{chat.Human}</p>
                        </div>
                        <div className='p-2 rounded-md'>
                            <p>{chat.AI}</p>
                        </div>
                    </div>
                ))}
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                    <div>
                        <div className="mt-1">
                        <textarea
                        rows={1}
                        name="command"
                        id="command"
                        resize="auto"
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        placeholder="Type command here..."
                        value={command}
                        onChange={(e) => setCommand(e.target.value)}
                        />
                        </div>
                    </div>

                    <div>
                        <button
                        type="submit"
                        className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                        Go
                        </button>
                    </div>
                    {loading && <p>Loading...</p>}
                    {errorMessage && <p>{errorMessage}</p>}
                    </form>
                </div>
            </div>
        </div>
        )
    }
  
    return (
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">{project.title}</h2>
              <p className='text-center pt-3'>{project.description}</p>
            </div>
            <div className="sm:mx-auto sm:w-full mt-8 w-full lg:px-64 sm:px-2">
                {chatData.map((chat) => (
                    <div key={chat.Human}>
                        <div className='bg-gray-200 p-2 rounded-md'>
                            <p>{chat.Human}</p>
                        </div>
                        <div className='p-2 rounded-md'>
                            <p>{chat.AI}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div>
                <div className="sm:mx-auto sm:w-full mt-8 w-full lg:px-64 sm:px-2">
                    <div className=" py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <form className="space-y-6" onSubmit={handleSubmit}>
                        <div className='flex flex-row'>
                            <div className="basis-4/5 pr-2">
                                <div>
                                    <textarea
                                    rows={1}
                                    name="command"
                                    id="command"
                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    placeholder="Type a command here..."
                                    value={command}
                                    onChange={(e) => setCommand(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className='basis-1/5'>
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                    Go
                                </button>
                            </div>
                        </div>
                        {loading && <p>Loading...</p>}
                        {errorMessage && <p>{errorMessage}</p>}
                        </form>
                    </div>
                </div>
                <p className='text-center pt-3'>Powered by <a className='underline text-indigo-500' href='https://www.getaspenai.com/' target="_blank">Aspen</a></p>

            </div>
        </div>
        )
}

export default LayoutThree;