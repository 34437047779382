import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import { AuthContextProvider } from './contexts/AuthContext';

import { ProjectContextProvider } from './contexts/projectContext';



ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <ProjectContextProvider>
        <App />
      </ProjectContextProvider>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
