import React, {useState } from 'react'
import { useAuthContext } from '../../hooks/useAuthContext';
import { useLogout } from '../../hooks/useLogout';
import { Link } from 'react-router-dom'


const LayoutOne = ({ project }) => {
  const { logout } = useLogout()
  const { user } = useAuthContext()
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState('')
  const [command, setCommand] = useState('')

  async function handleSubmit(e) {
      e.preventDefault()
      setLoading(true)
      const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/generalopenai', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ modelUsed: project.modelUsed, command, prompt: project.prompt, end: project.end, maxTokens: project.max_tokens, temperature: project.temperature, topP: project.top_p, frequencyPenalty: project.frequency_penalty, presencePenalty: project.presence_penalty}),
      })
      const json = await response.json()
      setLoading(false)
      setResult(json)
    }

  const handleLogout = () => {
    logout()
  }

  return (
    <div
    style={{backgroundColor: project.bgColor}}
    className="flex h-screen flex-col justify-center py-2 sm:px-6 lg:px-8">
      {/* <div className="mx-20 max-w-8xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 justify-between">
          <div className="flex">
          </div>
          <div className="flex sm:ml-6 sm:flex sm:items-center">
            {user && (
            <div style={{color: project.mainTextColor}} className='p-2 md:ml-6 md:mr-6 text-black'>
                <span style={{color: project.mainTextColor}} className="m-2 group w-full text-sm font-medium rounded-md ">{user.email}</span>
                <button onClick={handleLogout}
                    style={{backgroundColor: project.primaryColor, color: project.bgColor}}
                    className="m-2 group
                    py-2 px-4 bg-indigo-600 text-sm font-medium
                    rounded-md hover:bg-indigo-600
                    focus:outline-none focus:ring-2 focus:ring-offset-2
                    focus:ring-indigo-400" to="/login">Log out</button>
            </div>
            )}
            {!user && (
            <div className=" text-white">
                <Link 
                style={{backgroundColor: project.bgColor, color: project.mainTextColor, borderColor: project.primaryColor}}
                className="m-2 group w-full justify-center
                  py-2 px-4 border border-indigo-600 text-sm font-medium
                  rounded-md hover:bg-indigo-700
                  focus:outline-none focus:ring-2 focus:ring-offset-2
                  focus:ring-indigo-500" to="/login">Login</Link>
                <Link style={{backgroundColor: project.primaryColor}} className="m-2 group w-full  justify-center
                  py-2 px-4 border border-transparent text-sm font-medium
                  rounded-md bg-indigo-600 hover:bg-indigo-700
                  focus:outline-none focus:ring-2 focus:ring-offset-2
                  focus:ring-indigo-400" to="/signup">Signup</Link>
            </div>
            )}
          </div>
        </div>
      </div> */}
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 style={{color: project.mainTextColor}} className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">{project.title}</h2>
            <p style={{color: project.mainTextColor}} className="mt-2 text-center text-sm text-gray-600">{project.description}</p>
        </div>
        
        <div className="min-w-0 flex-1 sm:mx-auto sm:w-full sm:max-w-md mt-20">
            <div style={{backgroundColor: project.bgColor}} className=" py-8 px-4 rounded-md shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit}>
                <div style={{borderBlockColor: project.secondaryColor}}>
                <div className="mt-1">
                    <textarea
                    rows={3}
                    style={{backgroundColor: project.bgColor, color: project.mainTextColor}}
                    onFocus={e => {
                        e.target.style.borderColor = project.secondaryColor;
                        }}
                    onBlur={e => {
                    e.target.style.borderColor = project.bgColor;
                    }}

                    className="block rounded-md w-full appearance-none  border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
                    placeholder="Prompt for user input"
                    value={command}
                    onChange={(e) => setCommand(e.target.value)}
                    />
                </div>
                </div>

                <div>
                <button
                    type="submit"
                    style={{backgroundColor: project.primaryColor}}
                    className="flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium bg-indigo-600 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                    Go
                </button>
                </div>
            </form>

            <div style={{color: project.mainTextColor}} className="mt-6">
                <div className="relative">
                <div className="relative flex justify-center text-sm">
                  {result ? (
                    <p className='mx-5'>{result}</p>
                  ) : loading ? (
                    <p style={{backgroundColor: project.bgColor}} className="bg-gray-100 px-2 text-gray-400">Loading...</p>
                  ) : (
                    <p style={{backgroundColor: project.bgColor}} className="bg-gray-100 px-2 text-gray-400">// result prints here</p>
                  )}
                </div>
                </div>
            </div>
            </div>
        </div>
        <p className='justify-center relative flex py-5'><a style={{color: project.mainTextColor}} className='underline' href="/" target="_blank">Create your own AI-powered app with Aspen</a></p>
    </div>
  
  )
}

export default LayoutOne