import React from 'react';
import NavbarTwo from '../components/NavbarTwo'
import SandboxComponent from '../components/Sandbox'
import { useAuthContext } from '../hooks/useAuthContext';



const Sandbox = () => {
  const { user } = useAuthContext();

  if (!user) {
    return (
      <div className="pt-20 w-full min-h-screen flex-2 text-center">
        <p className="mt-2 text-md font-medium text-gray-900">Not logged in</p>
        <h3 className="mt-2 text-sm font-medium text-gray-900">Please <a className='underline text-indigo-500' href='/login'>sign in to your account</a> or <a className='underline text-indigo-500' href='signup'>create a new account</a></h3>
      </div>
    )
  }

  return (
    <div className='w-full min-h-screen flex-2'>
        <NavbarTwo />
        <header className="bg-white shadow">
          <div className="mx-20 max-w-7xl py-6 px-4 sm:px-6">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">Sandbox</h1>
          </div>
        </header>
        <SandboxComponent />
    </div>
  );
};

export default Sandbox;

