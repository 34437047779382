import React, {useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import LayoutOne from '../components/projectUIOptions/LayoutOne'
import LayoutTwo from '../components/projectUIOptions/LayoutTwo'
import LayoutThree from '../components/projectUIOptions/LayoutThree'

const TestDeployedProject = () => {
  const [project, setProject] = useState({})
  const [loading, setLoading] = useState(false)

  const params = useParams()
  const id = params.id

  useEffect(() => {
    setLoading(true)
    const fetchProject = async () => {
      const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/external/' + id, {
        method: 'GET',
      })

      const json = await response.json()
      
      if (response.ok) {
        setProject(json)
      }
      if (!response.ok) {
        console.log('error')
      }
      setLoading(false)
    }
    fetchProject()
  }, [id])


  return (
    <LayoutThree project={project} key={project._id}/>
  )
}

export default TestDeployedProject