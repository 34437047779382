import React, { useState, useRef } from 'react';
import {useNavigate} from 'react-router-dom';

import { useAuthContext } from '../hooks/useAuthContext';
import NavbarTwo from '../components/NavbarTwo';
import { XCircleIcon } from '@heroicons/react/20/solid'
import { CheckCircleIcon } from '@heroicons/react/20/solid'


const ModelFineTuning = () => {
  const navigate = useNavigate()
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false)
  const [successfulDataValidation, setSuccessfulDataValidation] = useState(-1);
  const [successfulFineTune, setSuccessfulFineTune] = useState(-1);
  const fileInput = useRef(null);
  const [modelName, setModelName] = useState('My Fine Tune')
  const [baseModel, setBaseModel] = useState('curie')
  const [training_file, setTrainingFile] = useState()
  const [recordID, setRecordID] = useState()
  const [modelID, setModelID] = useState()

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
  }


  async function updateRecord() {
    console.log('Updating record...')

    const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/finetune/update/' + recordID, {
      method: "PATCH",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      },
      body: JSON.stringify({
        openAIModelID: modelID
    })
    })

    const json = await response.json()

    if (!response.ok) {
      console.log('error updating record')
      setLoading(false)
    }
    if (response.ok) {
      console.log('success updating record')
      console.log(json)
      setLoading(false)
      navigate('/models/' + recordID)
    }
  }

  async function createFineTune(e) {
    e.preventDefault()
    setLoading(true)

    const model = baseModel

    const data = {training_file, model}

    const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/finetune/createfinetune', {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      },
      body: JSON.stringify(data)
    })

    const resJson = await response.json()


    if (!response.ok) {
      console.log('error')
      setSuccessfulFineTune(0)
      setLoading(false)
    }

    if (response.ok) {
      console.log('initiated fine tune')
      console.log(resJson.id)
      setModelID(resJson.id)
      console.log(modelID)
      setLoading(false)
      setSuccessfulFineTune(1)
    }
  }

  async function validateData(e) {
    e.preventDefault()
    setLoading(true)
    console.log("base model: " + baseModel)

    const file = fileInput.current.files[0];

    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('title', modelName)
    formData.append('base_model', baseModel);

    const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/finetune/createfinetunerecord', {
      method: "POST",
      headers: {
        'Authorization': `Bearer ${user.token}`
      },
      body: formData
    })

    const json = await response.json()
    
    if (!response.ok) {
      setSuccessfulDataValidation(0)
      setLoading(false)
    }

    if (response.ok) {
      setSuccessfulDataValidation(1)
      setTrainingFile(json.file_id)
      setRecordID(json._id)
      setLoading(false)
    }
  } 

  

    if (!user) {
        return (
          <div className="pt-20 w-full min-h-screen flex-2 text-center">
            <p className="mt-2 text-md font-medium text-gray-900">Not logged in</p>
            <h3 className="mt-2 text-sm font-medium text-gray-900">Please <a className='underline text-indigo-500' href='/login'>sign in to your account</a> or <a className='underline text-indigo-500' href='signup'>create a new account</a></h3>
          </div>
        )
      }
    return (
      <div className='w-full min-h-screen flex-2'>
          <NavbarTwo />
          <header className="bg-white shadow">
            <div className="mx-20 max-w-7xl py-6 px-4 sm:px-6">
              <h1 className="text-3xl font-bold tracking-tight text-gray-900">New Fine Tune</h1>
            </div>
          </header>
        <>
          <main className='mx-20 py-6 sm:px-6'>
            <div>
              <form onSubmit={createFineTune} className="space-y-8 divide-y divide-gray-200">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div>
                    <div>
                        <p className="mt-1 text-sm ">
                          Fine tuning is the process of taking a pretrained model and adapting it to a new task.
                        </p>
                    </div>
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Model Name
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                              required
                              type="text"
                              className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              value={modelName}
                              onChange={(e) => setModelName(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-1">
                          <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                              Base Model
                          </label>
                          <select
                              required
                              className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              defaultValue="curie"
                              onChange={(e) => setBaseModel(e.target.value)}
                          >
                              <option value={"curie"}>curie</option>
                              <option value={"ada"}>ada</option>
                              <option value={"babbage"}>babbage</option>
                              <option value={"davinci"}>davinci</option>
                          </select>
                      </div>

                      <div className="sm:col-span-6">
                        <label htmlFor="cover-photo" className="block text-sm font-medium text-gray-700">
                          Dataset
                        </label>
                        <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                          <div className="space-y-1 text-center">
                            <svg
                              className="mx-auto h-12 w-12 text-gray-400"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <div className="flex text-sm text-gray-600">
                              <label
                                htmlFor="file"
                                className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                              >
                                <input
                                  type="file"
                                  filename="file"
                                  ref={fileInput}
                                  onChange={handleFileUpload}
                                  />
                              </label>
                            </div>
                            <p className="text-xs text-gray-500">Must be a JSONL file</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='py-4'>
                        <p className="mt-1 text-sm">
                          It can take 30+ minutes to fine tune a model. Once the model is fine tuned, you can use it in a project.
                        </p>
                    </div>
                  </div>
                </div>

                <div className="pt-5">
                  <div className="flex justify-end">
                    <button
                      onClick={validateData}
                      encType="multipart/form-data"
                      type="button"
                      disabled={successfulDataValidation == 1}
                      className="disabled:cursor-not-allowed disabled:bg-gray-400 ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Validate data
                    </button>
                    <button
                      type="submit"
                      disabled={successfulDataValidation != 1 || successfulFineTune == 1}
                      className="disabled:cursor-not-allowed disabled:bg-gray-400 ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Initiate fine-tune
                    </button>
                    <button
                      onClick={updateRecord}
                      encType="multipart/form-data"
                      type="button"
                      disabled={successfulFineTune != 1}
                      className={successfulFineTune == 1 ? "ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" : "disabled:cursor-not-allowed ml-3 inline-flex justify-center rounded-md border border-transparent bg-gray-400 py-2 px-4 text-sm font-medium text-white shadow-sm"}
                    >
                      Finish
                    </button>
                  </div>
                </div>

                {loading ? (
                <div className="rounded-md bg-blue-50 p-4">
                  <div className="flex">
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                      <p className="text-sm text-blue-700">Loading...</p>
                    </div>
                  </div>
                </div>

                ) : null}
                {successfulDataValidation  == 0 ? (
                  <div className="rounded-md bg-red-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">Failed to validate data</h3>
                      </div>
                    </div>
                  </div>
                ) : successfulDataValidation == 1 ? (
                  <div className="rounded-md bg-green-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-medium text-green-800">Successfully validated data</p>
                      </div>
                    </div>
                  </div>
                ) : null}

                  {successfulFineTune  == 0 ? (
                  <div className="rounded-md bg-red-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">Failed to initiate fine-tune</h3>
                      </div>
                    </div>
                  </div>
                ) : successfulFineTune == 1 ? (
                  <div className="rounded-md bg-green-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-medium text-green-800">Successfully initiated fine tune</p>
                      </div>
                    </div>
                  </div>
                ) : null}
              </form>
            </div>
          </main>
        </>
      </div>
    )
  }
  

  export default ModelFineTuning