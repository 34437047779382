import React, { useState } from 'react';
import NavbarTwo from '../components/NavbarTwo';
import { useAuthContext } from '../hooks/useAuthContext';
import { XCircleIcon } from '@heroicons/react/20/solid'

const CreateChatbot = () => {
    const [chatbotName, setChatbotName] = useState('')
    const [baseUrl, setBaseUrl] = useState('')
    const [loading, setLoading] = useState(false)
    const [loadingState, setLoadingState] = useState('Loading...')
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState('')
    const { user } = useAuthContext();

    async function crawl(e) {
        e.preventDefault();
        setLoadingState('Crawling website...')
        const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/chatprojects/crawl', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ url: baseUrl }),
        })

        const json = await response.json()

        if (response.ok) {
            return json
        }
        if (!response.ok) {
            setLoading(false)
            setError(json.message)
            console.log(json)
            setChatbotName('')
            setBaseUrl('')
        }
    }

    async function initializeVectorStore(e) {
        e.preventDefault();
        setLoadingState('Initializing vector store...')

        const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/chatprojects/initdatastore/' , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ indexName: chatbotName }),
        })

        const json = await response.json()
        console.log(json)

        if (response.ok) {
            return json
        }
        if (!response.ok) {
            setLoading(false)
            setError(json.message)
            console.log(json)
            setChatbotName('')
            setBaseUrl('')
        }
    }

    async function checkVectorStore(e, vector_store_id) {
        e.preventDefault();
        setLoadingState('Waiting for vector store to initialize...')

        const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/chatprojects/pinecone/' + vector_store_id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
        })

        const json = await response.json()

        if (response.ok) {
            return json.status.ready
        }
        if (!response.ok) {
            setLoading(false)
            setError(json.message)
            console.log(json)
            setChatbotName('')
            setBaseUrl('')
        }
    }


    async function handleSubmit(e) {
        e.preventDefault();
        setLoading(true)

        console.log('crawling')
        const urls = await crawl(e)
        console.log(urls)

        console.log('initializing vector store')
        const vector_store_id = await initializeVectorStore(e)

        try {
            while (true) {
                const status = await checkVectorStore(e, vector_store_id)
                if (status) {
                    console.log('ready')
                    break;
                } else {
                    console.log('not ready')
                    await new Promise(r => setTimeout(r, 10000));
                }
            }
        }
        catch (err) {
            console.log(err)
            return;
        }

        setLoadingState('Creating chatbot...')
        console.log(chatbotName)
        console.log(baseUrl)
        console.log(vector_store_id)
        
        const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/chatprojects/createchatproject', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ indexName: chatbotName, urls: urls, id: vector_store_id }),
        })
        const json = await response.json()

        if (response.ok) {
            setLoading(false)
            setSuccess(true)
            setChatbotName('')
            setBaseUrl('')
        }
        if (!response.ok) {
            setLoading(false)
            setError(json.message)
            console.log(json)
            setChatbotName('')
            setBaseUrl('')
        }
    }

    // async function handleCancel(e) {
    //     e.preventDefault();
    //     setChatbotName('')
    //     setBaseUrl('')
    // }

    if (!user) {
        return (
          <div className="pt-20 w-full min-h-screen flex-2 text-center">
            <p className="mt-2 text-md font-medium text-gray-900">Not logged in</p>
            <h3 className="mt-2 text-sm font-medium text-gray-900">Please <a className='underline text-indigo-500' href='/login'>sign in to your account</a> or <a className='underline text-indigo-500' href='signup'>create a new account</a></h3>
          </div>
        )
      }

    return (
        <div className='w-full min-h-screen flex-2'>
            <NavbarTwo />
            <header className="bg-white shadow">
                <div className="mx-20 max-w-7xl pt-6 pb-3  px-4 sm:px-6">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900">New Chatbot</h1>
                </div>
            </header>
            <main className='mx-20 pb-3 px-4 sm:px-6 py-10'>
                <form onSubmit={handleSubmit}>
                    <div className="space-y-12">
                        <div className="border-b border-gray-900/10 pb-12">
                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-8">
                                <div className="sm:col-span-2">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Chatbot Name
                                    </label>
                                    <div className="mt-1 flex rounded-md shadow-sm">
                                        <input
                                            required
                                            type="text"
                                            placeholder='My Chatbot'
                                            value={chatbotName}
                                            onChange={(e) => setChatbotName(e.target.value)}
                                            className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Website
                                    </label>
                                    <div className="mt-1 flex rounded-md shadow-sm">
                                        <input
                                            required
                                            type="text"
                                            placeholder="https://www.mywebsite.com"
                                            value={baseUrl}
                                            onChange={(e) => setBaseUrl(e.target.value)}
                                            className="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        {/* <button onSubmit={handleCancel} type="button" className="text-sm font-semibold leading-6 text-gray-900">
                            Cancel
                        </button> */}
                        <button
                            {...loading && { disabled: true }}
                            type="submit"
                            className="disabled:cursor-not-allowed rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Create Chatbot
                        </button>
                    </div>
                    {loading &&
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <div className="flex items-center justify-center">
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                            </svg>
                        </div>
                        <p className="text-sm font-semibold leading-6 text-gray-900">{loadingState}</p>
                    </div>
                    }
                    {success &&
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <div className="flex items-center justify-center">
                            <svg className="h-5 w-5 text-green-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" clipRule="evenodd"
                                    d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523
                                    0 10 0zm5 7.414l-6.293 6.293a1 1 0 01-1.414
                                    0L5 10.414l1.293-1.293a1 1 0 011.414 0L9
                                    9.707l4.293-4.293a1 1 0 011.414 0L15 5.414z" />
                            </svg>
                        </div>
                        <p className="text-sm font-semibold leading-6 text-gray-900">Chatbot created successfully</p>
                    </div>
                    }
                    {error &&
                        <div className="rounded-md bg-red-50 p-4 mt-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                          </div>
                          <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-800">An error occured while creating your chatbot</h3>
                            <div className="mt-2 text-sm text-red-700">
                              <ul className="list-disc space-y-1 pl-5">
                                <li>{error}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                </form>
            </main>
        </div>
    )
}

export default CreateChatbot;