import React, { useState, useEffect } from 'react';
import NavbarTwo from '../../components/NavbarTwo';
import { useAuthContext } from '../../hooks/useAuthContext';
import MyProjects from '../../components/MyProjects';
import { useParams } from 'react-router-dom'


const User = () => {
    const { user } = useAuthContext();
    const [loading, setLoading] = useState(false)
    const [projects, setProjects] = useState([])

    const params = useParams()
    const user_id = params.id


    useEffect(() => {
        const fetchProjects = async () => {
          setLoading(true)
          const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/projects/user/' + user_id, {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
          })
          const json = await response.json()
    
          if (response.ok) {
            setProjects(json)
          }
          setLoading(false)
      }
      if (user) {
        fetchProjects()
      }
      }, [user])



    if (!user) {
        return (
          <div className="pt-20 w-full min-h-screen flex-2 text-center">
            <p className="mt-2 text-md font-medium text-gray-900">Not logged in</p>
            <h3 className="mt-2 text-sm font-medium text-gray-900">Please <a className='underline text-indigo-500' href='/login'>sign in to your account</a> or <a className='underline text-indigo-500' href='signup'>create a new account</a></h3>
          </div>
        )
      }

    if (user.email !== 'zaais@test.com' && user.email !== 'zaais@bountyboard.ca' && user.email !== 'francois@bountyboard.ca') {
        return (
            <div className="pt-20 w-full min-h-screen flex-2 text-center">
              <p className="mt-2 text-md font-medium text-gray-900">Unauthorized</p>
              <h3 className="mt-2 text-sm font-medium text-gray-900"><a className='underline text-indigo-500' href='/dashboard'>Return to dashboard</a></h3>
            </div>
          )
    }

  return (
    <div className='w-full min-h-screen flex-2'>
        <NavbarTwo />
        <header className="bg-white shadow">
          <div className="mx-20 max-w-7xl py-6 px-4 sm:px-6">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">User</h1>
            <p className="mt-1 text-sm text-gray-500">{user_id}</p>
          </div>
        </header>
        <main>
            <div className="mx-20 py-6 px-4 sm:px-6">
            <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
            {projects && projects.map((project) => (
              <MyProjects project={project} key={project._id} />
            ))}
          </ul>
          </div>
        </main>
    </div>
  )
}

export default User