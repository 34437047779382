import React, { useState } from 'react';

const RequestPasswordReset = () => {
    const [loading, setLoading] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null)
        setSuccessful(null)
        setLoading(true);
        const email = e.target.email.value;

        const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/user/sendresetpasswordlink', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email })
        })

        const json = await response.json()

        if (response.ok) {
            setLoading(false)
            setSuccessful(true)
        }
        if (!response.ok) {
            setLoading(false)
            setError(json)
        }
    }


    return (
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Request password reset</h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Request password reset
                </button>
              </div>
            </form>
            {error && <p className="block text-sm font-medium text-gray-700 pt-2">{error}</p>}
            {successful && <p className="block text-sm font-medium text-gray-700 pt-2">Password reset link sent to email.</p>}
            {loading && <p className="block text-sm font-medium text-gray-700 pt-2">Loading...</p>}
          </div>
        </div>
      </div>

    )
}

export default RequestPasswordReset;