import React, {useState } from 'react'

let messages = [
    {"role": "system", "content": "You are the leader of a highly effective sales team."},
]

const Email = () => {
    const [loading, setLoading] = useState(false)
    const [result, setResult] = useState('')
    const [context, setContext] = useState('We have had several conversations with this potential buyer, and things are going well. We are currently in the process of finalizing the proposal.')
    const [emailRequirements, setEmailRequirements] = useState('The email should contain a brief introduction, a summary of the proposal, and a call to action. The email should be roughly 50 words and specific to the client\'s needs.')

    async function handleSubmit(e) {
        e.preventDefault()
        setResult('')
        setLoading(true)
        messages.push({"role": "user", "content": "I need to write an email. " + context + " Here are the requirements for the email: " + emailRequirements})
        const response = await fetch('https://swe-jarvis-one-a.herokuapp.com/api/chat', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ messages: messages })
        })
        const json = await response.json()
        setLoading(false)
        setResult(json.content)
        console.log(json.content)
    }

  return (
    <div>
        <div className="p-2 sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Email Generator</h2>
            <p className="mt-2 text-center text-md text-gray-600">Powered by Aspen</p>
        </div>
        
        <div className='flex flex-row'>
            <div className="basis-2/5 text-black rounded-2xl h-full p-3 m-3 bg-white">
            <div>
            <div className="py-6 px-4 rounded-md sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                <h2 className="text-xl font-bold text-gray-900">Inputs</h2>

                <div className="mt-1">
                    <label htmlFor="company" className="pb-2 block text-md font-medium text-gray-700">
                    Context
                    </label>
                    <textarea
                    rows={8}
                    className="block rounded-md w-full appearance-none  border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm"
                    placeholder="The email context is..."
                    value={context}
                    onChange={(e) => setContext(e.target.value)}
                    />
                </div>
                <div className="mt-5">
                    <label htmlFor="company" className="pb-2 block text-md font-medium text-gray-700">
                    Email Content
                    </label>
                    <textarea
                    rows={6}
                    className="block rounded-md w-full appearance-none  border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm"
                    placeholder="The email should contain..."
                    value={emailRequirements}
                    onChange={(e) => setEmailRequirements(e.target.value)}
                    />
                </div>
                </div>

                <div>
                <button
                    type="submit"
                    disabled={loading}
                    className="flex disabled:bg-indigo-300 disabled:cursor-not-allowed w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium bg-indigo-600 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                    {loading ? ( <p>Loading...</p>) : ( <p>Generate Email</p>)}
                </button>
                </div>
            </form>
            </div>
        </div>
            </div>
            <div className="basis-3/5 text-black rounded-2xl p-3 m-3 bg-white">
                <div className="mt-6 mx-5">
                <h2 className="text-xl font-bold text-gray-900">Sales Proposal</h2>
                    <div className="mt-1 relative">
                    <div className="relative flex text-md">
                    {result ? (
                        <p className='whitespace-pre-wrap'>{result}</p>
                    ) : loading ? (
                        <div class="py-4 w-full mx-auto">
                        <div class="animate-pulse flex space-x-4">
                          <div class="flex-1 space-y-6 py-1">
                            <div class="h-2 bg-slate-700 rounded"></div>
                            <div class="space-y-3">
                              <div class="grid grid-cols-3 gap-4">
                                <div class="h-2 bg-slate-700 rounded col-span-2"></div>
                                <div class="h-2 bg-slate-700 rounded col-span-1"></div>
                              </div>
                              <div class="h-2 bg-slate-700 rounded"></div>
                            </div>
                            <div class="space-y-3">
                              <div class="grid grid-cols-4 gap-4">
                                <div class="h-2 bg-slate-700 rounded col-span-1"></div>
                                <div class="h-2 bg-slate-700 rounded col-span-3"></div>
                              </div>
                              <div class="h-2 bg-slate-700 rounded"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                        <p className="text-gray-500">Result prints here</p>
                    )}
                    </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
  
  )
}

export default Email