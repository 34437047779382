import { Link, NavLink } from 'react-router-dom'
import { useAuthContext } from '../hooks/useAuthContext';
import { useLogout } from '../hooks/useLogout';


import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'


export default function NavbarTwo() {
  const { logout } = useLogout()
  const { user } = useAuthContext()

  const activeHeader = "inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-200"
  const inactiveHeader = "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-200 hover:border-gray-300 hover:text-gray-700"

  const handleLogout = () => {
    logout()
  }

  return (
    <Disclosure as="nav" className="bg-lessdarkdiv shadow">
      {({ open }) => (
        <>
          <div className="mx-20 max-w-8xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="flex flex-shrink-0 items-center">
                  <p className='text-5xl text-indigo-500 font-bold mt-3'>^</p>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                    <NavLink
                        to="/dashboard"
                        className={({ isActive }) => (isActive ? activeHeader : inactiveHeader)}
                    >
                        <span className="capitalize ">Dashboard</span>
                    </NavLink>
                    <NavLink
                        to="/sandbox"
                        className={({ isActive }) => (isActive ? activeHeader : inactiveHeader)}
                    >
                        <span className="capitalize ">Sandbox</span>
                    </NavLink>
                    <NavLink
                        to="/projects"
                        className={({ isActive }) => (isActive ? activeHeader : inactiveHeader)}
                    >
                        <span className="capitalize ">Projects</span>
                    </NavLink>
                    <NavLink
                        to="/projectbuilder"
                        className={({ isActive }) => (isActive ? activeHeader : inactiveHeader)}
                    >
                        <span className="capitalize ">Project Builder</span>
                    </NavLink>
                    <NavLink
                        to="/models"
                        className={({ isActive }) => (isActive ? activeHeader : inactiveHeader)}
                    >
                        <span className="capitalize ">Fine Tunes</span>
                    </NavLink>
                    <NavLink
                        to="//docs.getaspenai.com"
                        target="_blank"
                        className={({ isActive }) => (isActive ? activeHeader : inactiveHeader)}
                    >
                        <span className="capitalize ">Docs</span>
                    </NavLink>
                </div>
              </div>
              <div className="flex sm:ml-6 sm:flex sm:items-center">
                {user && (
                <div className='p-2 md:ml-6 md:mr-6 text-white'>
                  <a href='/settings'>
                    <span className="m-2 group w-full text-sm font-medium rounded-md ">{user.email}</span>
                  </a>
                    <button onClick={handleLogout} className="m-2 group
                        py-2 px-4 border border-indigo-500 text-sm font-medium
                        rounded-md hover:bg-indigo-600
                        focus:outline-none focus:ring-2 focus:ring-offset-2
                        focus:ring-indigo-400" to="/login">Log out</button>
                </div>
                )}
                {!user && (
                <div className=" text-white">
                    <Link className="m-2 group w-full justify-center
                      py-2 px-4 border border-indigo-600 text-sm font-medium
                      rounded-md hover:bg-indigo-700
                      focus:outline-none focus:ring-2 focus:ring-offset-2
                      focus:ring-indigo-500" to="/login">Login</Link>
                    <Link className="m-2 group w-full  justify-center
                      py-2 px-4 border border-transparent text-sm font-medium
                      rounded-md bg-indigo-600 hover:bg-indigo-700
                      focus:outline-none focus:ring-2 focus:ring-offset-2
                      focus:ring-indigo-400" to="/signup">Signup</Link>
                </div>
                )}
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pt-2 pb-3">
              <Disclosure.Button
                as="a"
                href="/test"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
              >
                Dashboard
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="/projects"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
              >
                Projects
              </Disclosure.Button>
              {/* <Disclosure.Button
                as="a"
                href="/settings"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
              >
                Settings
              </Disclosure.Button> */}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
